import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
	toggleShowAddCoupon,
	fetchAddCoupon,
	fetchAddPartnerCoupon,
	setAddCouponErrorMsg,
} from "features/account/addCouponSlice";
import Loader from "../Loader";

export default function AddCouponModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, limit, isOpen, errorMsg } = useSelector(
		(state) => state.addCoupon
	);
	const { user } = useSelector((state) => state.session);
	// State
	const [coupon, setCoupon] = useState("");
	// Handlers
	function handleCouponChange(e) {
		if (errorMsg != null && errorMsg.coupon != null) {
			dispatch(
				setAddCouponErrorMsg({
					errorMsg: { ...errorMsg, coupon: null },
				})
			);
		}
		setCoupon(e.target.value);
	}
	function handleClose() {
		dispatch(toggleShowAddCoupon({ isOpen }));
	}
	function handleAddCoupon() {
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (coupon.trim() === "") {
			errorMessages = {
				...errorMessages,
				coupon: "Debes escribir el código del cupón.",
			};
			valid = false;
		} else {
			if (coupon.length < 8) {
				errorMessages = {
					...errorMessages,
					coupon: "Debes escribir un cupón válido",
				};
				valid = false;
			}
		}
		if (valid) {
			if (user.user.type === 3) dispatch(fetchAddCoupon({ coupon }));
			if (user.user.type === 4) dispatch(fetchAddPartnerCoupon({ coupon }));
		} else {
			dispatch(setAddCouponErrorMsg({ errorMsg: errorMessages }));
		}
	}
	// Render functions
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	function renderAddText() {
		if (user.user != null && user.user.type === 3) {
			return (
				<div>
					<p>
						Para incrementar el límite de llantas puedes cargar un nuevo cupón
						Family &amp; Friends.
					</p>
				</div>
			);
		}
		if (
			user.user != null &&
			limit != null &&
			limit.limite === 0 &&
			user.user.type === 4
		) {
			return (
				<div>
					<p>
						Si cuentas con un cupón de ContiPartners para el año en curso, lo
						puedes cargar para poder realizar compras.
					</p>
				</div>
			);
		}
		if (
			user.user != null &&
			limit != null &&
			limit.limite > 0 &&
			user.user.type === 4
		) {
			return (
				<div>
					<p>
						Ya cuentas con un cupón de ContiPartners activo para el año en
						curso, por lo que no puedes agregar un segundo cupón.
					</p>
				</div>
			);
		}
		return null;
	}
	function renderLimit() {
		if (!isLoading && limit != null) {
			return (
				<div>
					<p>
						Cuentas con un total de <strong>{limit.limite} llantas</strong>.
						<br />
						Hasta el momento has comprado{" "}
						<strong>{limit.acumulado} llantas</strong>.<br />
						Tienes disponibles{" "}
						<strong>{limit.limite - limit.acumulado} llantas</strong> para
						comprar.
					</p>
				</div>
			);
		}
		return null;
	}
	function renderError(error) {
		if (error != null) {
			if (error !== "") {
				return <li>{error}</li>;
			}
			return null;
		}
		return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return <ul className="error-list">{renderError(errorMsg.coupon)}</ul>;
		} else {
			return null;
		}
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			return {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			return { className: "error" };
		}
		return {};
	}

	function renderForm() {
		if (
			!isLoading &&
			user.user != null &&
			limit != null &&
			(user.user.type === 3 || (user.user.type === 4 && limit.limite === 0))
		) {
			return (
				<div class="register-form">
					<div>
						<div {...getFieldProps("coupon")}>
							<label>Cupón</label>
							<input
								type="text"
								placeholder="Cupón"
								value={coupon}
								onChange={handleCouponChange}
								autoComplete="contishop-unique-coupon-code"
								className="modal-field"
							></input>
						</div>
						<div className="right">
							<div className="button" onClick={handleAddCoupon}>
								Activar Cupón
							</div>
						</div>
					</div>
					<div>
						<div className="full">{renderErrorList()}</div>
					</div>
				</div>
			);
		}
		return null;
	}
	// Render
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleClose}
				contentLabel="Agregar cupon"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleClose}></div>
					<h2>Agregar Cupón</h2>
					{renderLoader()}
					{renderLimit()}
					{renderAddText()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
