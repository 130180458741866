import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import Scroll from "react-scroll";
import { imageFormat, getBrandClass } from "features/functions";
import {
	loadSearchSuggestions,
	updateSearchItem,
	updateSearchValue,
	clearSearchSuggestions,
	clearSearchActions,
	clearSearchValue,
} from "features/tireFinder/searchSlice";
import {
	fetchSearchResultsTireListIfNeeded,
	hideSearchResultsList,
} from "features/tireResults/searchTiresSlice";
import { clearSizeTiresList } from "features/tireResults/sizeTiresSlice";
import { clearSizeSaeTiresList } from "features/tireResults/sizeSaeTiresSlice";
import { clearVehicleTiresData } from "features/tireResults/vehicleTiresSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";

const getClassName = (showField) => {
	if (!showField) return "search-hide";
	else return "search-show";
};

const scroll = Scroll.animateScroll;

export default function Search() {
	const dispatch = useDispatch();
	// Selectors
	const {
		showField,
		value,
		suggestions,
	} = useSelector((state) => state.search);
	// Refs
	const searchRef = useRef();
	// Handlers
	function handleClearSearchClick() {
		scroll.scrollTo(0, { smooth: true, delay: 100, duration: 500 });
		dispatch(clearSearchValue());
		dispatch(clearSearchActions());
	}
	function onSuggestionsFetchRequested({ value }) {
		dispatch(loadSearchSuggestions(value));
	}
	function onSuggestionSelected(event, { suggestion }) {
		dispatch(updateSearchItem({ selectedItem: suggestion }));
		dispatch(fetchSearchResultsTireListIfNeeded(suggestion.Id));
		dispatch(clearSizeTiresList());
		dispatch(clearSizeSaeTiresList());
		dispatch(clearVehicleTiresData());
		dispatch(hideHighlightsTiresList({ hide: true }));
		dispatch(hideSearchResultsList({hide: false}));
		const target = document.getElementById("resultCarousel");
		const targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		searchRef.current.blur();
	}
	function onSuggestionsClearRequested() {
		dispatch(clearSearchSuggestions());
	}
	function onChange(event, { newValue }) {
		dispatch(updateSearchValue({ value: newValue }));
		dispatch(updateSearchItem({ selectedItem: {} }));
		dispatch(hideHighlightsTiresList({ hide: false }));
	}
	function getSuggestionValue(suggestion) {
		return suggestion.Name;
	}
	function renderSuggestion(suggestion) {
		return (
			<div className="search-item">
				<div>
					<div>{suggestion.Name}</div>
					<div className="search-slogan">{suggestion.Slogan}</div>
				</div>
				<div>
					<img
						className="search-product-img"
						src={imageFormat(suggestion.Image)}
						alt={suggestion.Name}
					/>
					<span className={"search-" + getBrandClass(suggestion.Brand)}></span>
				</div>
			</div>
		);
	}
	// Conditional elements
	let clearSearch;
	if (value.length === 0 && getClassName(showField) === "search-hide") {
		clearSearch = null;
	} else {
		clearSearch = (
			<div className="clear-search" onClick={handleClearSearchClick}></div>
		);
	}
	// Render
	const inputProps = {
		placeholder: "Buscar...",
		value,
		onChange,
		tabIndex: 1,
		ref: searchRef,
	};
	return (
		<div id="search" className={getClassName(showField)}>
			<div className="search-input">
				<Autosuggest
					highlightFirstSuggestion
					suggestions={suggestions}
					onSuggestionsFetchRequested={onSuggestionsFetchRequested}
					onSuggestionsClearRequested={onSuggestionsClearRequested}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					onSuggestionSelected={onSuggestionSelected}
					inputProps={inputProps}
					id="search-field"
				/>
				{clearSearch}
			</div>
		</div>
	);
}
