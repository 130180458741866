import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	// userId: null,
	message: null,
	// hasQueryString: false,
};
const activation = createSlice({
	name: "activation",
	initialState,
	reducers: {
		requestActivation(state) {
			state.isLoading = true;
		},
		receiveActivation(state, action) {
			state.isLoading = false;
			state.message = action.payload.message;
		},
		// setActivationId(state, action) {
		// 	state.userId = action.payload.userId;
		// },
		toggleShowActivation(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		// setActivationQueryString(state, action) {
		// 	state.hasQueryString = action.payload.hasQueryString;
		// },
		clearActivationData: () => initialState,
	},
});

export const {
	requestActivation,
	receiveActivation,
	//setActivationId,
	toggleShowActivation,
	//setActivationQueryString,
	clearActivationData,
} = activation.actions;

// export const evaluateShowActivation = () => async (dispatch, getState) => {
// 	const theState = getState();
// 	if (theState.activation.userId != null) {
// 		dispatch(toggleShowActivation({ isOpen: false }));
// 	} else {
// 		dispatch(receiveActivation(null));
// 		dispatch(setActivationId(null));
// 	}
// };

export const fetchActivation = (userId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	// const theState = getState();
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			id: userId,
		}),
	};
	//dispatch(setActivationId({userId}))
	dispatch(requestActivation());
	const url = BASE_API_URL + "/api/Cuenta/Activacion/";
	let responseStatus = 0;
	return fetch(url, options)
		.then((response) => {
			responseStatus = response.status;
			if (response.ok) {
				return response.json();
			} else {
				return Promise.reject("Ocurrió un error");
			}
		})
		.then((json) => {
			ReactGA.event({
				category: "Cuenta",
				action: "Activar Cuenta",
			});
			dispatch(receiveActivation({ message: json }));
			// dispatch(toggleShowActivation({isOpen: false}));
		})
		.catch((error) => {
			// TODO: fetch error actions
			// If 401 Unauthorized login failed
			if (responseStatus === 401) {
				dispatch(runUnauthorizedActions());
			}
			else if (responseStatus === 404) {
				dispatch(receiveActivation({message: "No se pudo encontrar la clave de activación, por favor verifica que la url sea correcta."}))
			} else {
				ReactGA.event({
					category: "Cuenta",
					action: "Error al Activar Cuenta",
				});
				dispatch(receiveActivation({ message: error.Message }));
				// dispatch(toggleShowActivation({isOpen: false}));
			}
		});
};

export default activation.reducer;
