import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import qs from "qs";
import Scroll from "react-scroll";
import { BASE_API_URL, VEHICLE_TYPES } from "features/constants";
import { fetchAccount, setRedirectUrl } from "./accountSlice";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";
import {
	clearHighlightsData,
	fetchHighlightsTiresListIfNeeded,
} from "features/tireResults/highlightsTiresSlice";
import {
	clearVehicleBrandData,
	fetchVehicleBrandsIfNeeded,
} from "features/tireFinder/vehicleBrandsSlice";
import { clearVehicleModelData } from "features/tireFinder/vehicleModelsSlice";
import { clearVehicleYearData } from "features/tireFinder/vehicleYearsSlice";
import { clearVehicleVersionData } from "features/tireFinder/vehicleVersionsSlice";
import { clearVehicleTiresData } from "features/tireResults/vehicleTiresSlice";
import {
	clearWidthDiameterData,
	fetchWidthDiametersIfNeeded,
} from "features/tireFinder/sizeWidthDiametersSlice";
import { clearSizeRatiosData } from "features/tireFinder/sizeRatiosSlice";
import { clearSizeRimData } from "features/tireFinder/sizeRimsSlice";
import { clearSizeTiresData } from "features/tireResults/sizeTiresSlice";
import { clearWidthSaeData } from "features/tireFinder/sizeSaeWidthsSlice";
import { clearSizeRimSaesData } from "features/tireFinder/sizeSaeRimsSlice";
import { clearSizeSaeTiresData } from "features/tireResults/sizeSaeTiresSlice";
import {
	clearCartData,
	fetchCartObjectIfNeeded,
} from "features/cart/cartSlice";
import {
	clearDealerShipAddressData,
	fetchDealerShipAddressListIfNeeded,
} from "features/cart/dealerShipAddressSlice";
import { fetchPaymentCardList, fetchPaymentCardListIfNeeded } from "features/cart/paymentCardSlice";
import {
	clearSearchData,
	fetchSearchIfNeeded,
} from "features/tireFinder/searchSlice";
import { clearSearchResultData } from "features/tireResults/searchTiresSlice";
import { clearOrdersData } from "features/orders/ordersSlice";
import { clearOrderConfirmationData } from "features/orders/orderConfirmationSlice";
import { clearAddCouponData } from "./addCouponSlice";
import { fetchBannersList } from "features/banners/bannersSlice";
import { clearAccountCardsData, fetchAccountCards } from "./accountCardsSlice";

const initialState = {
	isLoading: false,
	errorMsg: null,
};
const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		requestLoginData(state) {
			state.isLoading = true;
		},
		receiveLoginData(state) {
			state.isLoading = false;
		},
		setLoginErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
	},
});

export const { requestLoginData, receiveLoginData, setLoginErrorMessage } =
	loginSlice.actions;

export const fetchLogin =
	(user, password, isPartner = false) =>
	async (dispatch) => {
		let headers = {
			"Content-Type": "application/x-www-form-urlencoded",
		};
		let options = {
			method: "POST",
			headers: headers,
			body: qs.stringify({
				username: user,
				password: password,
				partner: isPartner,
				grant_type: "password",
			}),
		};
		dispatch(requestLoginData());
		return fetch(BASE_API_URL + "/FamilyFriends/Token", options)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return response.text().then((text) => Promise.reject(text));
				}
			})
			.then((json) => {
				sessionService
					.saveSession({ loginData: json })
					.then(() => dispatch(fetchAccount()))
					.catch((error) => console.log("error", error));
				dispatch(receiveLoginData());
				dispatch(setLoginErrorMessage({ errorMsg: null }));
			})
			.catch((error) => {
				if (error.includes("error_description")) {
					const json = JSON.parse(error);
					dispatch(
						setLoginErrorMessage({
							errorMsg: { error: json.error_description },
						})
					);
				} else {
					dispatch(
						setLoginErrorMessage({
							errorMsg: {
								error: "El usuario o la contraseña son incorrectos.",
							},
						})
					);
				}
				dispatch(receiveLoginData());
				//history.push("/");
			});
	};
export const runAuthenticatedActions = () => async (dispatch) => {
	sessionService.loadSession().then(() => {
		sessionService.loadUser().then((user) => {
			switch (user.user.type) {
				case 3:
				default:
					dispatch(setRedirectUrl({ redirectUrl: "/login" }));
					break;
				case 4:
					dispatch(setRedirectUrl({ redirectUrl: "/partners" }));
					break;
			}
		});
		dispatch(fetchBannersList());
		dispatch(fetchCartObjectIfNeeded());
		dispatch(fetchDealerShipAddressListIfNeeded());
		dispatch(fetchVehicleBrandsIfNeeded());
		dispatch(fetchHighlightsTiresListIfNeeded());
		dispatch(fetchPaymentCardListIfNeeded());
		dispatch(fetchWidthDiametersIfNeeded(VEHICLE_TYPES.CAR));
		dispatch(fetchSearchIfNeeded());
		dispatch(fetchAccountCards());
		dispatch(fetchPaymentCardList());
	});
};
export const runUnauthorizedActions =
	(logout = false) =>
	async (dispatch) => {
		sessionService.deleteSession();
		sessionService.deleteUser();
		dispatch(clearCartData());
		dispatch(clearDealerShipAddressData());
		dispatch(clearVehicleBrandData());
		dispatch(clearVehicleModelData());
		dispatch(clearVehicleYearData());
		dispatch(clearVehicleVersionData());
		dispatch(clearVehicleTiresData());
		dispatch(clearWidthDiameterData());
		dispatch(clearSizeRatiosData());
		dispatch(clearSizeRimData());
		dispatch(clearSizeTiresData());
		dispatch(clearWidthSaeData());
		dispatch(clearSizeRimSaesData());
		dispatch(clearSizeSaeTiresData());
		// dispatch(clearCodeTiresList());
		dispatch(clearHighlightsData());
		dispatch(clearSearchData());
		dispatch(clearSearchResultData());
		dispatch(clearOrdersData());
		dispatch(clearOrderConfirmationData());
		dispatch(clearAddCouponData());
		dispatch(clearAccountCardsData());
		const scroll = Scroll.animateScroll;
		scroll.scrollTo(0, { smooth: true, delay: 100, duration: 500 });
		if (logout === false) {
			dispatch(
				setAlert({
					alert: {
						title: "Aviso",
						description:
							"Tu sesión ha caducado, para continuar con tu compra debes volver a iniciar sesión.",
						details: null,
					},
				})
			);
			dispatch(toggleShowAlert({ isOpen: false }));
		}
	};
export const doLogout = () => async (dispatch) => {
	ReactGA.event({
		category: "Cuenta",
		action: "Cerrar Sesión",
	});
	dispatch(runUnauthorizedActions(true));
};

export default loginSlice.reducer;
