import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";
import axios from "axios";

const initialState = {
	isLoading: false,
	list: null,
	selected: null,
};
const paymentCard = createSlice({
	name: "paymentCard",
	initialState,
	reducers: {
		requestPaymentCardList(state) {
			state.isLoading = true;
		},
		receivePaymentCardList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setPaymentCardSelected(state, action) {
			state.selected = action.payload.selected;
		},
		clearPaymentCardData: () => initialState,
	},
});

export const {
	requestPaymentCardList,
	receivePaymentCardList,
	setPaymentCardSelected,
	clearPaymentCardData,
} = paymentCard.actions;

const shouldFetchPaymentCardList = (state) => {
	if (state.paymentCard.list == null) {
		return true;
	}
	return false;
};

export const fetchPaymentCardListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchPaymentCardList(theState)) {
			return dispatch(fetchPaymentCardList());
		}
	};

export const fetchPaymentCardList = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			dispatch(requestPaymentCardList());
			const url = BASE_API_URL + "/api/Carritos/PromosBancos";
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receivePaymentCardList({ list: response.data }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default paymentCard.reducer;
