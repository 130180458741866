import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDropdown from "react-dropdown";
import { SHIP_TYPES as s, PAYMENT_METHODS as p } from "features/constants";
import { currencyFormat, getModalButtonClass } from "features/functions";
import {
	setCartActiveTab,
	setCartPaymentType,
	evaluateCartNextStep,
	setCartPaymentCard,
} from "features/cart/cartSlice";
import { setPaymentCardSelected } from "features/cart/paymentCardSlice";

const isPaynetDisabled = (total, shipping, shipType) => {
	let amount = total * 1.16;
	if (shipType === s.DHL_EMPLEADOS) {
		amount = total * 1.16 + shipping;
	}
	if (amount > 29999.99) {
		return true;
	}
	return false;
};

export default function CartPayment() {
	const dispatch = useDispatch();
	// Selectors
	const {
		cartObject,
		shipType,
		paymentType,
		paymentCard,
		showTab,
	} = useSelector((state) => state.cart);
	const cardOptions = useSelector((state) => state.accountCards.options);
	const [selectedCardOption, setSelectedCardOption] = useState(null);
	useEffect(()=> {
		if (selectedCardOption == null && cardOptions.length > 0) {
			setSelectedCardOption(cardOptions[0]);
		}
	},[selectedCardOption])
	// Handlers
	function handlePrevClick() {
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	}
	function handleNextClick() {
		if (paymentType !== 0) {
			dispatch(evaluateCartNextStep(showTab + 1));
		}
	}
	function hadlePaynetList() {
		window.open("https://www.paynet.com.mx/", "_blank");
	}
	function handleSelectPayment(type) {
		dispatch(setCartPaymentType({ paymentType: type }));
		if (type !== p.CREDITCARD) {
			dispatch(setPaymentCardSelected({ selected: null }));
		}
	}
	function handleSelectCard(option) {
		dispatch(
			setCartPaymentCard({
				paymentCard: option.value,
				paymentCardItem: option,
			})
		);
		setSelectedCardOption(option);
	}
	// Render functions
	function getCssClass(selected) {
		if (selected) {
			return "payment-item selected";
		}
		return "payment-item";
	}
	function renderPaymentButtonImg(type, isDisabled) {
		if (isDisabled) {
			return (
				<img
					src={p.properties[type].disabledImageUrl}
					alt={p.properties[type].name}
				/>
			);
		}
		return (
			<img src={p.properties[type].imageUrl} alt={p.properties[type].name} />
		);
	}
	function renderCreditCardDropdown() {
		if (paymentType === p.CREDITCARD) {
			const selectedOption = selectedCardOption == null ?
				cardOptions.filter((el) => el.value === paymentCard)[0] : selectedCardOption;
			if (selectedOption.length === 0) {
				return null;
			}
			return (
				<div>
					<div>Seleccionar Tarjeta</div>
					<ReactDropdown
						options={cardOptions}
						onChange={handleSelectCard}
						value={selectedOption}
						placeholder="Seleccione"
					/>
				</div>
			);
		}
			return null;
	}
	function renderPaynet() {
		const isDisabled = isPaynetDisabled(
			cartObject.Total,
			cartObject.Costo_Envio,
			shipType
		);
		return (
			<div
				className={
					isDisabled
						? "payment-item disabled"
						: getCssClass(paymentType === p.PAYNET)
				}
			>
				<div>
					<span className="icon-check"></span>
					{p.properties[p.PAYNET].name}
				</div>
				<div
					className="payment-button"
					onClick={() => handleSelectPayment(p.PAYNET)}
				>
					{renderPaymentButtonImg(p.PAYNET, isDisabled)}
				</div>
				<div className="alert-box">
					<span className="close-box"></span>El monto máximo permitido varía
					dependiendo de cada establecimiento afiliado.
				</div>
				<div className="conti-link" onClick={hadlePaynetList}>
					Ver lista de establecimientos
				</div>
			</div>
		);
	}
	// Render
	if (cartObject == null) {
		return null;
	}
	const totalAmount =
		shipType === s.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;

	return (
		<div id="cart-content-4">
			<div id="cart-content-payment">
				<div className={getCssClass(paymentType === p.CREDITCARD)}>
					<div>
						<span className="icon-check"></span>
						{p.properties[p.CREDITCARD].name}
					</div>
					<div
						className="payment-button"
						onClick={() => handleSelectPayment(p.CREDITCARD)}
					>
						{renderPaymentButtonImg(p.CREDITCARD, false)}
					</div>
					{renderCreditCardDropdown()}
				</div>
				{/*<div className={getCssClass(paymentType === p.PAYPAL)}>
					<div>
						<span className="icon-check"></span>
						{p.properties[p.PAYPAL].name}
					</div>
					<div
						className="payment-button"
						onClick={() => handleSelectPayment(p.PAYPAL)}
					>
						{renderPaymentButtonImg(p.PAYPAL, false)}
	</div>*/}
					{/* <div className="alert-box"><span className="close-box"></span>Debido a cambios con la plataforma de PayPal esta forma de pago no estará disponible hasta nuevo aviso.</div> */}
				{/*</div>*/}
				{renderPaynet()}
			</div>
			<div id="cart-summary-2">
				<div id="cart-coupon">&nbsp;</div>
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{currencyFormat(
								shipType === s.DHL_EMPLEADOS ? cartObject.Costo_Envio : 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div>{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>
							<div className="modal-button" onClick={handlePrevClick}>
								Anterior
							</div>
						</div>
						<div>
							<div
								className={getModalButtonClass(
									paymentType == null
								)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
