import { useState, useEffect } from "react";
import packageJson from "../../package.json";
global.appVersion = packageJson.version;

// version from 'meta.json' - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA.split(/\./g);

	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

export default function CacheBuster(props) {
	// State
	const [loading, setLoading] = useState(true);
	const [isLatestVersion, setIsLatestVersion] = useState(true);
	// Effect
	useEffect(() => {
		const BASE_API_URL = process.env.REACT_APP_API_URL;
		const url = BASE_API_URL + "/api/FamilyFriends/SoftwareVersions";
		fetch(url)
			.then((response) => response.json())
			.then((meta) => {
				const latestVersion = meta.version;
				const currentVersion = global.appVersion;

				const shouldForceRefresh = semverGreaterThan(
					latestVersion,
					currentVersion
				);
				if (shouldForceRefresh) {
					console.log(
						`We have a new version - ${latestVersion}. Should force refresh`
					);
					setLoading(false);
					setIsLatestVersion(false);
				} else {
					console.log(
						`You already have the latest version - ${latestVersion}. No cache refresh needed.`
					);
					setLoading(false);
					setIsLatestVersion(true);
				}
			});
	}, []);
	function refreshCacheAndReload() {
		console.log("Clearing cache and hard reloading...");
		if (caches != null) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(async function (names) {
				await Promise.all(names.map((name) => caches.delete(name)));
			});
		}

		// delete browser cache and hard reload
		window.location.reload(true);
	}
	return props.children({
		loading,
		isLatestVersion,
		refreshCacheAndReload,
	});
}
