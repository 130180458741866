import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL, VEHICLE_TYPES } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
	width: null,
	ratio: null,
	rim: null,
	dept: null,
};
const sizeTires = createSlice({
	name: "sizeTires",
	initialState,
	reducers: {
		requestSizeTiresList(state) {
			state.isLoading = true;
		},
		receiveSizeTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.width = action.payload.width;
			state.ratio = action.payload.ratio;
			state.rim = action.payload.rim;
			state.dept = action.payload.dept;
		},
		clearSizeTiresList(state) {
			state.list = null;
			state.width = null;
			state.ratio = null;
			state.rim = null;
		},
		clearSizeTiresData: () => initialState,
	},
});

export const {
	requestSizeTiresList,
	receiveSizeTiresList,
	clearSizeTiresList,
	clearSizeTiresData,
} = sizeTires.actions;

const shouldFetchSizeTiresList = (state, newRim) => {
	if (state.sizeTires.list == null) {
		return true;
	}
	const oldRim = state.sizeRims.selectedItem;
	if (oldRim !== newRim) {
		return true;
	}
	return false;
};

export const fetchSizeTiresListIfNeeded =
	(selectedRim) => async (dispatch, getState) => {
		if (shouldFetchSizeTiresList(getState(), selectedRim)) {
			const theState = getState();
			return dispatch(
				fetchSizeTiresList(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue,
					theState.sizeRatios.selectedItem.Value,
					selectedRim.Value
				)
			);
		}
	};

export const fetchSizeTiresList =
	(dept, width, ratio, rim) => async (dispatch) => {
		sessionService
			.loadSession()
			.then((session) => {
				const headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
				const options = {
					method: "GET",
					headers: headers,
				};
				dispatch(requestSizeTiresList());
				const url =
					BASE_API_URL +
					"/api/FamilyFriends/Productos/Buscar?anchoseccion=" +
					width +
					"&serie=" +
					ratio +
					"&rin=" +
					rim +
					"&depto=" +
					dept +
					"&marca=0";
				let responseStatus = 0;
				return fetch(url, options)
					.then((response) => {
						responseStatus = response.status;
						if (response.ok) {
							return response.json();
						} else {
							return Promise.reject("Ocurrió un error");
						}
					})
					.then((json) => {
						ReactGA.send({
							hitType: "pageview",
							page:
								"/llantas/medida/" +
								VEHICLE_TYPES.properties[dept].slug +
								"/" +
								width +
								"/" +
								ratio +
								"R" +
								rim,
						});
						dispatch(
							receiveSizeTiresList({ list: json, width, ratio, rim, dept })
						);
					})
					.catch((error) => {
						// TODO: fetch error actions
						// If 401 Unauthorized login failed
						if (responseStatus === 401) {
							dispatch(runUnauthorizedActions());
						}
					});
			})
			.catch((err) => console.log("Error", err));
	};

export default sizeTires.reducer;
