import React from "react";

export default function TermsDisclaimer() {
	return (
		<div>
			<h3>TÉRMINOS Y CONDICIONES DE USO</h3>
			<p>
				<strong>Nombre de la Promoción: </strong>"Promoción EMPLEADOS FAMILY &amp; FRIENDS".
				Vigencia de la Promoción: al 31 de diciembre del año en curso.
			</p>
			<p><strong>Mecánica de la promoción</strong></p>
			<ol>
				<li>
					Los empleados de Continental Tire Corporativo tendrán 5 cupones, de 4
					llantas cada cupón, dando un total de 20 llantas por empleado, los
					cuales podrán obsequiar a sus amigos y familiares para comprar llantas
					en la plataforma de CONTISHOP EMPLEADOS, obteniendo el mismo precio de
					empleado así como el servicio de instalación y balanceo incluidos.
				</li>
				<li>
					Aplican solo para llantas de PLT (Auto y camioneta) en las 4 marcas
					del grupo (Continental, Euzkadi, General Tire y Barum).
				</li>
				<li>
					Las llantas serán entregadas e instaladas en las sucursales
					autorizadas dentro del sitio web Contishop Empleados.
				</li>
			</ol>
			<p><strong>Incentivos ofrecidos</strong></p>
			<p>
				Precio empleado, para un máximo de 4 llantas por cupón, envío sin costo
				e instalación gratis (montaje, válvula y balanceo).
			</p>
			<p>
				El incentivo no podrá ser canjeado por ningún otro bien ni por su valor
				en efectivo.
			</p>
			<p>
				Cobertura: La cobertura está limitada a los siguientes puntos de
				entrega.
			</p>
			<ol>
				<li>
					<strong>Irapuato, Guanajuato: </strong>
					<br />
					Sucursal Jasman Irapuato
				</li>
				<li>
					<strong>León, Guanajuato: </strong>
					<br />
					Sucursal Jasman Morelos
				</li>
				<li>
					<strong>Guadalajara, Jalisco: </strong>
					<br />
					Sucursal Jasman López Mateos
				</li>
				<li>
					<strong>Zapopan, Jalisco: </strong>
					<br />
					Sucursal Jasman Patria
				</li>
				<li>
					<strong>Huixquilucan, México: </strong>
					<br />
					Sucursal Jasman Interlomas
				</li>
				<li>
					<strong>Naucalpan, México: </strong>
					<br />
					Sucursal Jasman Lomas Verdes
				</li>
				<li>
					<strong>Cuautla, Morelos: </strong>
					<br />
					Sucursal Jasman Cuautla
				</li>
				<li>
					<strong>Monterrey, Nuevo León: </strong>
					<br />
					Sucursal Jasman Universidad
				</li>
				<li>
					<strong>Querétaro, Querétaro: </strong>
					<br />
					Sucursal Jasman Querétaro
				</li>
				<li>
					<strong>San Luis Potosí, San Luis Potosí: </strong>
					<br />
					Sucursal Tocollanta Matriz
				</li>
			</ol>
			<p>
				Facturación: Al ser un beneficio trasladado por parte de los empleados
				de Continental, estas compras solo recibirán una factura de compra sin
				datos fiscales del contribuyente.
			</p>
			<p>
				Productos que se excluyen de la promoción: Quedan expresamente excluidas
				de esta promoción las llantas para camiones y motocicletas de cualquier
				medida.
			</p>
			<p>
				CONTINENTAL TIRE CORPORATIVO, S.A. DE C.V. en su carácter de responsable
				de la promoción tiene derecho a realizar modificaciones sobre la
				mecánica y vigencia de la promoción, las cuales se comunicarán a través
				del sitio web de CONTISHOP.
			</p>
			<p>
				La información registrada por cada consumidor obrará en la base de datos
				de CONTINENTAL TIRE CORPORATIVO, S.A. DE C.V. siendo tratada bajo el
				carácter de confidencial y será protegida y utilizada de conformidad al
				Aviso de Privacidad contenido en la página
				https://empleados.contishop.com.mx/ a la cual se someten todos los
				participantes de la promoción.
			</p>
		</div>
	);
}
