import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Store from "components/store/Store";
import { runAuthenticatedActions } from "features/account/loginSlice";
import { fetchSearch } from "features/tireFinder/searchSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import {
	setOrderConfirmationId,
	fetchOrderConfirmation,
	toggleShowOrderConfirmation,
} from "features/orders/orderConfirmationSlice";

function App(props) {
	const dispatch = useDispatch();
	// Selectors
	const { authenticated, checked } = useSelector((state) => state.session);
	const { redirectUrl } = useSelector((state) => state.account);
	// On load
	useEffect(() => {
		dispatch(runAuthenticatedActions());
		const { params } = props.match;
		if (params != null) {
			if (params.productId != null) {
				dispatch(fetchSearch(params.productId));
				dispatch(hideHighlightsTiresList({ hide: true }));
			}
			// if (params.productcode != null) {
			// 	dispatch(fetchCodeTiresListIfNeeded(params.productcode));
			// 	dispatch(hideHighlightsTiresList({ hide: true }));
			// }
			if (params.orderId != null) {
				if (params.orderId != null) {
					dispatch(setOrderConfirmationId({ orderId: params.orderId }));
					dispatch(toggleShowOrderConfirmation({ isOpen: false }));
					dispatch(fetchOrderConfirmation());
				}
			}
		}
	}, [dispatch, props.match]);
	if (checked && !authenticated) {
		return (
			<Redirect
				to={{ pathname: redirectUrl, state: { from: props.location.pathname } }}
			/>
		);
	}
	return <Store />;
}
export default App;
