export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

export const USER_TYPE = {
	FAMILY: 3,
	PARTNER: 4,
}

export const CART_STEPS = {
	DETAILS: 1,
	SHIP: 2,
	PAYMENT: 3,
	SUMMARY: 4,
};

export const SHIP_TYPES = {
	ADDRESS: 1,
	FACTORY: 2,
	EMPLEADOS: 4,
	DHL_EMPLEADOS: 5,
	DEALER: 6,
	properties: {
		1: {
			name: "Domicilio",
			shortname: "Domicilio",
			fullname: "Enviar a una Dirección",
		},
		2: {
			name: "Factory Store",
			shortname: "Factory",
			fullname: "Recoger en Factory Store",
		},
		4: {
			name: "Empleados",
			shortname: "Empleados",
			fullname: "Recoger en Recursos Humanos",
		},
		5: {
			name: "Localidad DHL",
			shortname: "DHL Empleados",
			fullname: "Recoger en DHL",
		},
		6: {
			name: "Sucursal Distribuidor",
			shortname: "Distribuidor",
			fullname: "Recoger con Distribuidor",
		},
	},
};
export const PAYMENT_METHODS = {
	DEPOSIT: 1,
	CREDITCARD: 2,
	PAYPAL: 3,
	SAFETYPAY: 5,
	PAYNET: 6,
	properties: {
		1: {
			name: "Depósito Bancario",
			shortname: "Depósito",
			fullname: "Depósito en Sucursal Bancaria",
			imageUrl: "/images/cart/logo-deposito-bbva-bancomer.png",
			disabledImageUrl: "/images/cart/logo-deposito-bbva-bancomer_disabled.png",
		},
		2: {
			name: "Tarjeta de Crédito",
			shortname: "TarjetaCrédito",
			fullname: "Tarjeta de Crédito",
			imageUrl: "/images/cart/logo-tc-visa-mastercard.png",
			disabledImageUrl: "/images/cart/logo-tc-visa-mastercard_disabled.png",
		},
		3: {
			name: "PayPal",
			shortname: "PayPal",
			fullname: "PayPal Pagos Seguros",
			imageUrl: "/images/cart/logo-paypal.png",
			disabledImageUrl: "/images/cart/logo-paypal_disabled.png",
		},
		5: {
			name: "SafetyPay",
			shortname: "SafetyPay",
			fullname: "SafetyPay Pagos Seguros",
			imageUrl: "/images/cart/logo-safetypay.png",
			disabledImageUrl: "/images/cart/logo-safetypay_disabled.png",
		},
		6: {
			name: "Pago en Establecimientos",
			shortname: "Paynet",
			fullname: "Pago en Establecimientos",
			imageUrl: "/images/cart/logo-paynet-openpay.png",
			disabledImageUrl: "/images/cart/logo-paynet-openpay_disabled.png",
		},
	},
};
export const OPENPAY_CARD_LIMIT = 3;

export const OPENPAY_TOKEN_ACTION = {
	PAY: 1,
	SAVE: 2,
}
export const VEHICLE_TYPES = {
	PLT: 0,
	CAR: 1,
	SUV: 2,
	BIKE: 3,
	VAN: 4,
	properties: {
		0: { name: "Auto/Camioneta" },
		1: { name: "Auto" },
		2: { name: "Camioneta" },
		3: { name: "Moto" },
		4: { name: "Van" },
	},
};
export const ORDER_STATUS_TYPES = {
	INCOMPLETE: 10,
	RESERVE: 15,
	VALIDATION: 18,
	AUTORIZED: 20,
	PROCESS: 21,
	NOT_AUTHORIZED: 23,
	NOT_PAYED: 26,
	DELIVERY: 40,
	DELIVERY_FACTORY: 45,
	DELIVERY_EMPLEADOS: 47,
	SHIPED: 48,
	TRANSIT: 50,
	DHL_LOCATION: 55,
	HR_LOCATION: 58,
	DEALER_LOCATION: 59,
	DELIVERED: 60,
	EXCEPTON: 700,
	CANCELLED: 900,
	properties: {
		10: {
			name: "Incompleto",
			shortname: "Incompleto",
			className: "icon-invalid",
		},
		15: {
			name: "En Espera de su Pago",
			shortname: "EsperandoPago",
			className: "icon-pending",
		},
		18: {
			name: "Pago Recibido",
			shortname: "PagoRecibido",
			className: "icon-pending",
		},
		20: {
			name: "Pago Validado",
			shortname: "PagoValidado",
			className: "icon-valid",
		},
		21: { name: "En Proceso", shortname: "EnProceso", className: "icon-valid" },
		23: {
			name: "No Autorizado",
			shortname: "NoAutorizado",
			className: "icon-invalid",
		},
		26: { name: "NoPagado", shortname: "NoPagado", className: "icon-invalid" },
		40: {
			name: "Preparando Envío",
			shortname: "PreparandoEnvío",
			className: "icon-valid",
		},
		45: {
			name: "Enviado a Factory Store",
			shortname: "Enviado",
			className: "icon-valid",
		},
		47: { name: "Enviado", shortname: "Enviado", className: "icon-valid" },
		48: { name: "Enviado", shortname: "Enviado", className: "icon-valid" },
		50: {
			name: "En Tránsito",
			shortname: "EnTránsito",
			className: "icon-valid",
		},
		55: {
			name: "En Sucursal DHL",
			shortname: "SucursalDHL",
			className: "icon-valid",
		},
		58: {
			name: "En Recursos Humanos",
			shortname: "LocalidadRH",
			className: "icon-valid",
		},
		59: {
			name: "En Sucursal de Distribuidor",
			shortname: "SucursalDistribuidor",
			className: "icon-valid",
		},
		60: { name: "Entregado", shortname: "Entregado", className: "icon-valid" },
		700: {
			name: "Excepción",
			shortname: "Excepción",
			className: "icon-invalid",
		},
		900: {
			name: "Cancelado",
			shortname: "Cancelado",
			className: "icon-invalid",
		},
	},
};

export const IMAGE_TYPES = {
	CART: 1,
	DETAILS: 2,
	CAROUSEL: 3,
}

export const TIRE_SPECS = {
	Camara: [
		{
			Key: "TL",
			Value: "Sin cámara",
		},
		{
			Key: "TT",
			Value: "Con cámara",
		},
	],
	Cara: [
		{
			Key: "BSW",
			Value: "Black Sidewall",
		},
		{
			Key: "LW",
			Value: "White Letter Sidewall",
		},
		{
			Key: "OBL",
			Value: "Outline Black Letter",
		},
		{
			Key: "OWL",
			Value: "Outline White Letters",
		},
		{
			Key: "PW",
			Value: "White Sidewall",
		},
		{
			Key: "SBL",
			Value: "Serrated Black Letter",
		},
		{
			Key: "SRL",
			Value: "Solid Red Letter",
		},
		{
			Key: "VUL",
			Value: "Vulcanette Sidewall",
		},
		{
			Key: "WW",
			Value: "White Sidewall",
		},
	],
	Carga: [
		{
			Key: "FR",
			Value: "FRT",
		},
		{
			Key: "LL",
			Value: "LIGHT LOAD",
		},
		{
			Key: "RF",
			Value: "REINFORCED",
		},
		{
			Key: "S1",
			Value: "STAR 1 *",
		},
		{
			Key: "S2",
			Value: "STAR 2 **",
		},
		{
			Key: "S3",
			Value: "STAR 3 ***",
		},
		{
			Key: "SL",
			Value: "STAND.LOAD",
		},
		{
			Key: "VS",
			Value: "VERSTAERKT",
		},
		{
			Key: "XL",
			Value: "EXTRA LOAD",
		},
	],
	Construccion: [
		{
			Key: "CT",
			Value: "CTS TIRE",
		},
		{
			Key: "LT",
			Value: "LIGHT TRUCK",
		},
		{
			Key: "P",
			Value: "P METRIC",
		},
		{
			Key: "ST",
			Value: "STEERING TRAILER",
		},
		{
			Key: "T",
			Value: "TEMPORARY USE",
		},
	],
	Radial: [
		{
			Key: "  ",
			Value: "Not Relevant",
		},
		{
			Key: "- ",
			Value: "Bias",
		},
		{
			Key: "A ",
			Value: "CSEasy Adapter",
		},
		{
			Key: "B ",
			Value: "Bias Belted",
		},
		{
			Key: "C ",
			Value: "CSE-Robust",
		},
		{
			Key: "G ",
			Value: "Radial GS",
		},
		{
			Key: "L ",
			Value: "Radial GST",
		},
		{
			Key: "R ",
			Value: "Radial",
		},
		{
			Key: "T ",
			Value: "Robust",
		},
		{
			Key: "U ",
			Value: "2-PC",
		},
		{
			Key: "V ",
			Value: "Vollreifen",
		},
		{
			Key: "W ",
			Value: "3-PC",
		},
		{
			Key: "X ",
			Value: "Hohlreifen",
		},
		{
			Key: "Y ",
			Value: "CSEasy",
		},
		{
			Key: "ZR",
			Value: "Radial",
		},
	],
	Velocidad: [
		{
			Key: "A1",
			Value: "5 KM/H",
		},
		{
			Key: "A2",
			Value: "10 KM/H",
		},
		{
			Key: "A3",
			Value: "15 KM/H",
		},
		{
			Key: "A4",
			Value: "20 KM/H",
		},
		{
			Key: "A5",
			Value: "25 KM/H",
		},
		{
			Key: "A6",
			Value: "30 KM/H",
		},
		{
			Key: "A7",
			Value: "35 KM/H",
		},
		{
			Key: "A8",
			Value: "40 KM/H",
		},
		{
			Key: "B",
			Value: "50 KM/H",
		},
		{
			Key: "C",
			Value: "60 KM/H",
		},
		{
			Key: "D",
			Value: "65 KM/H",
		},
		{
			Key: "E",
			Value: "70 KM/H",
		},
		{
			Key: "F",
			Value: "80 KM/H",
		},
		{
			Key: "G",
			Value: "90 KM/H",
		},
		{
			Key: "H",
			Value: "210 KM/H",
		},
		{
			Key: "J",
			Value: "100 KM/H",
		},
		{
			Key: "K",
			Value: "110 KM/H",
		},
		{
			Key: "L",
			Value: "120 KM/H",
		},
		{
			Key: "M",
			Value: "130 KM/H",
		},
		{
			Key: "N",
			Value: "140 KM/H",
		},
		{
			Key: "P",
			Value: "150 KM/H",
		},
		{
			Key: "Q",
			Value: "160 KM/H",
		},
		{
			Key: "R",
			Value: "170 KM/H",
		},
		{
			Key: "S",
			Value: "180 KM/H",
		},
		{
			Key: "T",
			Value: "190 KM/H",
		},
		{
			Key: "V",
			Value: "240 KM/H",
		},
		{
			Key: "W",
			Value: "270 KM/H",
		},
		{
			Key: "Y",
			Value: "300 KM/H",
		},
	],
};
