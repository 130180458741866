import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL, VEHICLE_TYPES } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
	diameter: null,
	width: null,
	rim: null,
	dept: 0,
};
const sizeSaeTires = createSlice({
	name: "sizeSaeTires",
	initialState,
	reducers: {
		requestSizeSaeTiresList(state) {
			state.isLoading = true;
		},
		receiveSizeSaeTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.diameter = action.payload.diameter;
			state.width = action.payload.width;
			state.rim = action.payload.rim;
			state.dept = action.payload.dept;
		},
		clearSizeSaeTiresList(state) {
			state.list = null;
			state.diameter = null;
			state.width = null;
			state.rim = null;
		},
		clearSizeSaeTiresData: () => initialState,
	},
});

export const {
	requestSizeSaeTiresList,
	receiveSizeSaeTiresList,
	clearSizeSaeTiresList,
	clearSizeSaeTiresData,
} = sizeSaeTires.actions;

const shouldFetchSizeSaeTiresList = (state, newRim) => {
	if (state.sizeSaeTires.list == null) {
		return true;
	}
	const oldRim = state.sizeSaeRims.selectedItem;
	if (oldRim !== newRim) {
		return true;
	}
	return false;
};

export const fetchSizeSaeTiresListIfNeeded =
	(selectedRim) => async (dispatch, getState) => {
		if (shouldFetchSizeSaeTiresList(getState(), selectedRim)) {
			const theState = getState();
			return dispatch(
				fetchSizeSaeTiresList(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem,
					theState.sizeSaeWidths.selectedItem,
					selectedRim.Value
				)
			);
		}
	};

export const fetchSizeSaeTiresList =
	(dept, diameter, width, rim) => async (dispatch) => {
		sessionService
			.loadSession()
			.then((session) => {
				const headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
				const options = {
					method: "GET",
					headers: headers,
				};
				dispatch(requestSizeSaeTiresList());
				const url =
					BASE_API_URL +
					"/api/FamilyFriends/Productos/Buscar/SAE/?diametro=" +
					diameter.OriginalValue +
					"&anchoseccion=" +
					width.OriginalValue +
					"&rin=" +
					rim +
					"&depto=" +
					dept +
					"&marca=0";
				let responseStatus = 0;
				return fetch(url, options)
					.then((response) => {
						responseStatus = response.status;
						if (response.ok) {
							return response.json();
						} else {
							return Promise.reject("Ocurrió un error");
						}
					})
					.then((json) => {
						ReactGA.send({
							hitType: "pageview",
							page:
								"/llantas/medida/" +
								VEHICLE_TYPES.properties[dept].slug +
								"/" +
								encodeURIComponent(
									diameter.Value + "X" + width.Value + "R" + rim
								),
						});
						dispatch(
							receiveSizeSaeTiresList({
								list: json,
								diameter: diameter.Value,
								width: width.Value,
								rim,
								dept,
							})
						);
					})
					.catch((error) => {
						// TODO: fetch error actions
						// If 401 Unauthorized login failed
						if (responseStatus === 401) {
							dispatch(runUnauthorizedActions());
						}
					});
			})
			.catch((err) => console.log("Error", err));
	};

export default sizeSaeTires.reducer;
