import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	errorMsg: null,
	done: false,
};

const contact = createSlice({
	name: "contact",
	initialState,
	reducers: {
		requestContactMessage(state) {
			state.isLoading = true;
		},
		receiveContactMessage(state) {
			state.isLoading = false;
		},
		setContactErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setContactDone(state, action) {
			state.done = action.payload.done;
		},
		clearContactForm: () => initialState,
	},
});

export const {
	requestContactMessage,
	receiveContactMessage,
	setContactErrorMsg,
	setContactDone,
	clearContactForm,
} = contact.actions;

export const fetchContactMessage = (data) => async (dispatch) => {
	let headers;
	let sess;
	// Validate Priority
	const priority = data.priority != null ? null : false;
	await sessionService
		.loadSession()
		.then((session) => {
			headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			sess = session;
		})
		.catch((err) => {
			headers = {
				"Content-Type": "application/json",
			};
		});
	const name =
		sess != null && sess.user != null
			? sess.user.user.name + " " + sess.user.user.lastname
			: data.name.trim();
	const email = sess != null && sess.user != null ? null : data.mail.trim();
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			Name: name,
			Email: email,
			Message: data.message.trim(),
			Type: data.selected.value,
			Priority: priority,
		}),
	};
	const url = BASE_API_URL + "/api/FamilyFriends/Contacto";
	let responseStatus = 0;
	dispatch(requestContactMessage());
	return fetch(url, options)
		.then((response) => {
			responseStatus = response.status;
			if (response.ok) {
				return response.text();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then(() => {
			dispatch(receiveContactMessage());
			dispatch(setContactDone({ done: true }));
		})
		.catch((error) => {
			dispatch(receiveContactMessage());
			// If 401 Unauthorized login failed
			if (responseStatus === 401) {
				dispatch(runUnauthorizedActions());
			}
			if (responseStatus === 400) {
				dispatch(
					setContactErrorMsg({
						errorMsg: {
							system:
								"Ocurrió un error al intentar enviar tu mensaje. Por favor intenta más tarde",
						},
					})
				);
			}
			if (responseStatus === 404) {
				dispatch(setContactErrorMsg({ errorMsg: { system: error } }));
			}
		});
};

export default contact.reducer;
