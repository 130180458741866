import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: "0",
};

const vehicleType = createSlice({
	name: "vehicleType",
	initialState,
	reducers: {
		setVehicleType(state, action) {
			state.value = action.payload.vehicleType;
		},
	}
});

export const {
	setVehicleType,
} = vehicleType.actions;

export default vehicleType.reducer;
