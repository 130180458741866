import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";

const resetPassword = createSlice({
	name: "resetPassword",
	initialState: {
		isLoading: false,
		isOpen: false,
		resetForm: true,
		errorMsg: null,
	},
	reducers: {
		requestPassword(state) {
			state.isLoading = true;
		},
		completePassword(state) {
			state.isLoading = false;
		},
		toggleShowPassword(state, action) {
			state.isOpen = !action.payload.isOpen;
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Mostrar Recuper Contraseña",
				});
			}
		},
		setPasswordErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
	},
});

export const {
	requestPassword,
	completePassword,
	toggleShowPassword,
	setPasswordErrorMsg,
} = resetPassword.actions;

export const fetchPasswordReset = (email) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			email: email,
		}),
	};
	dispatch(requestPassword());
	return fetch(
		BASE_API_URL + "/api/FamilyFriends/Cuenta/RecuperaContrasena",
		options
	)
		.then((response) => {
			if (response.ok) {
				return response.text();
			} else {
				return Promise.reject("Ocurrió un error.");
			}
		})
		.then(() => {
			ReactGA.event({
				category: "Cuenta",
				action: "Recuperar Contraseña",
			});
			dispatch(
				setAlert({
					alert: {
						title: "¡Excelente!",
						description: "Se ha enviado el correo con tu nueva contraseña.",
						details: null,
					},
				})
			);
			dispatch(toggleShowPassword({ isOpen: true }));
			dispatch(toggleShowAlert({ isOpen: false }));
			dispatch(setPasswordErrorMsg({ errorMsg: null }));
		})
		.catch((error) => {
			ReactGA.event({
				category: "Cuenta",
				action: "Error al Recuperar Contraseña",
			});
			dispatch(
				setAlert({
					alert: {
						title: "¡Lo sentimos!",
						description:
							"No se ha podido enviar el correo de recuperación de contraseña. Verifique los datos y vuelva a intentar.",
						details: error.Message,
					},
				})
			);
			dispatch(completePassword());
			dispatch(setPasswordErrorMsg({ errorMsg: error.Message }));
			dispatch(toggleShowAlert({ isOpen: false }));
		});
};

export default resetPassword.reducer;
