import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import Scroll from "react-scroll";
import { fetchLogin, setLoginErrorMessage } from "features/account/loginSlice";
import Loader from "components/Loader";
import AlertModal from "../alert/AlertModal";
import ActivationModal from "./ActivationModal";
import bgImage from "images/login-bg-ff.jpg";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

const scroll = Scroll.animateScroll;

export default function Login(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg } = useSelector((state) => state.login);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	const [pwd, setPwd] = useState("");
	// Refs
	const usernameRef = useRef();
	const passwordRef = useRef();
	useEffect(() => usernameRef.current && usernameRef.current.focus(), []);
	// Redirect
	if (checked && authenticated) {
		return (
			<Redirect
				to={{ pathname: "/", state: { from: props.location.pathname } }}
			/>
		);
	}
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handleUsrnameKeyPress(e) {
		if (e.key === "Enter") {
			e.preventDefault();
			passwordRef.current.focus();
		}
	}
	function handlePwdChange(e) {
		if (errorMsg != null && errorMsg.pwd != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, pwd: null, error: null },
				})
			);
		}
		setPwd(e.target.value);
	}
	function handleCancel() {
		ReactGA.send({ hitType: "pageview", page: "/home" });
		scroll.scrollTo(0, { smooth: true, delay: 100, duration: 500 });
		dispatch(setLoginErrorMessage({ errorMsg: null }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		}
		if (pwd.trim() === "") {
			errorMessages = {
				...errorMessages,
				pwd: "Debes escribir tu contraseña",
			};
			valid = false;
		}
		if (valid) {
			dispatch(fetchLogin(usrname, pwd));
		} else {
			dispatch(setLoginErrorMessage({ errorMsg: errorMessages }));
		}
	}
	let errors = null;
	if (errorMsg != null) {
		errors = (
			<div className="error-list">
				<ul>
					{renderError(errorMsg.error)}
					{renderError(errorMsg.usrname)}
					{renderError(errorMsg.pwd)}
				</ul>
			</div>
		);
	}
	let content;
	if (isLoading) {
		content = (
			<form>
				<div id="login-content" style={{ backgroundImage: `url(${bgImage})` }}>
					<div>
						<h1>
							ContiShop <br />
							Family &amp; Friends
						</h1>
						<p>
							La tienda en línea para amigos y familiares de los empleados de
							Continental Tire
						</p>
						<Loader msg="Iniciando sesión..." />
					</div>
				</div>
			</form>
		);
	} else {
		content = (
			<form onSubmit={handleSubmit}>
				<div id="login-content" style={{ backgroundImage: `url(${bgImage})` }}>
					<div>
						<h1>
							ContiShop <br />
							Family &amp; Friends
						</h1>
						<p>
							La tienda en línea para amigos y familiares de los empleados de
							Continental Tire
						</p>
						<div className="form-a">
							<div>
								<div>
									<label>Usuario</label>
									<input
										type="text"
										value={usrname}
										autoComplete="username"
										placeholder="Usuario"
										ref={usernameRef}
										onChange={handleUsrnameChange}
										onKeyPress={handleUsrnameKeyPress}
									></input>
								</div>
							</div>
						</div>
						<div className="form-a">
							<div>
								<div>
									<label>Contraseña</label>
									<input
										type="password"
										value={pwd}
										onChange={handlePwdChange}
										placeholder="Contraseña"
										autoComplete="current-password"
										ref={passwordRef}
									></input>
								</div>
							</div>
						</div>
						{errors}
						<div>
							<button type="submit" className="button main">
								Iniciar Sesión
							</button>
						</div>
						<div>
							<Link
								to={"/registro"}
								onClick={() =>
									ReactGA.send({ hitType: "pageview", page: "/registro" })
								}
							>
								Registrarme
							</Link>
						</div>
						<div>
							<Link
								to={"/confirmacion/family"}
								onClick={() =>
									ReactGA.send({
										hitType: "pageview",
										page: "/confirmacion/family",
									})
								}
							>
								No recibí correo de confirmación
							</Link>
						</div>
						<div>
							<hr />
							<Link to={"/home"} onClick={handleCancel}>
								<button type="button" className="button">
									Cancelar
								</button>
							</Link>
						</div>
					</div>
				</div>
			</form>
		);
	}
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			{content}
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<ActivationModal />
			<AlertModal />
		</div>
	);
}
