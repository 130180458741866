import React from "react";
import history from "features/history";
import ContactForm from "components/contact/ContactForm";
export default function StoreFooter() {
	// Handlers
	function handleTermsClick() {
		history.push("/terminos");
	}
	// Render
	return (
		<div id="footer-view">
			<ContactForm />
			<div id="footer-data">
				<h3>Sitio</h3>
				<aside style={{width: "100%"}}>
					<ul>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Términos y Condiciones
							</div>
						</li>
					</ul>
					<div className="sponsor">
						<p>&nbsp;</p>
						<p>
							<img
								src="/images/footer/logo-tecnologia-alemana-inverted.png"
								alt="Tecnología Alemana que conecta tu vida"
								style={{ width: "150px" }}
							/>
						</p>
					</div>
					<div className="sponsor">
						<p>
							<img
								src="/images/footer/esr-logo.png"
								alt="Empresa Socialmente Responsable"
								style={{ width: "124px" }}
							/>
						</p>
					</div>
				</aside>
			</div>
		</div>
	);
}
