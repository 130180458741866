import React from "react";
import ReactDOM from "react-dom";
import { sessionService } from "redux-react-session";
import { Provider } from "react-redux";
import { setDefaultLocale } from "react-datepicker";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import es from "date-fns/locale/es";
import "./fonts.css";
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from "./serviceWorker";
import history from "features/history";
import initStore from "app/store";
import { GOOGLE_ANALYTICS } from "features/constants";
import packageJson from "../package.json";

import "./fonts.css";
import "./style.css";

import CacheBuster from "components/CacheBuster";
import App from "components/App";
import Login from "components/account/Login";
import PartnerLogin from "components/account/PartnerLogin";
import Register from "components/account/Register";
import Confirmation from "components/account/Confirmation";
import Terms from "components/account/Terms";
import AccountActivation from "components/account/AccountActivation";
import Home from "components/home/Home";

ReactGA.initialize(GOOGLE_ANALYTICS, {});
ReactGA.event({
	category: "Software",
	action: "Version",
	label: packageJson.version,
});

Modal.setAppElement("#root");
setDefaultLocale(es);

const store = initStore(history);

sessionService.initSessionService(store);

ReactDOM.render(
	<CacheBuster>
		{({ loading, isLatestVersion, refreshCacheAndReload }) => {
			if (loading) return null;
			if (!loading && !isLatestVersion) {
				// You can decide how and when you want to force reload
				refreshCacheAndReload();
			}
			return (
				<React.StrictMode>
					<Provider store={store}>
						<ConnectedRouter history={history}>
							<Switch>
								<Route exact path="/" component={App} />
								<Route exact path="/home" component={Home} />
								<Route exact path="/login" component={Login} />
								<Route exact path="/partners" component={PartnerLogin} />
								<Route exact path="/registro" component={Register} />
								<Route
									exact
									path="/confirmacion/:type"
									component={Confirmation}
								/>
								<Route exact path="/terminos" component={Terms} />
								<Route exact path="/activacion" component={AccountActivation} />
								<Route exact path="/partnersactivacion" component={AccountActivation} />
								<Route path="/eventos/:eventKey" component={App} />
								<Route path="/product/:productid" component={App} />
								<Route path="/code/:productcode" component={App} />
								<Route path="/order/:orderId" component={App} />
							</Switch>
						</ConnectedRouter>
					</Provider>
				</React.StrictMode>
			);
		}}
	</CacheBuster>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
