import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowSearchField } from "features/tireFinder/searchSlice";
import { setCartActiveTab, toggleShowCart } from "features/cart/cartSlice";
import Search from "components/tireFinder/Search";
import AccountMenu from "components/account/AccountMenu";

export default function MainMenu() {
	const dispatch = useDispatch();
	// Selectors
	const { showField } = useSelector((state) => state.search);
	const { cartObject } = useSelector((state) => state.cart);
	// Handlers
	function handleSearchClick() {
		dispatch(toggleShowSearchField({ showField }));
	}
	function handleShowCartClick() {
		dispatch(setCartActiveTab({ showTab: 1 }));
		dispatch(toggleShowCart({ isOpen: false }));
	}
	// Render
	const totalItems = cartObject != null ? cartObject.Articulos : 0;
	return (
		<div id="main-menu">
			<div id="logo-conti-top-mobile">
				<span></span>
			</div>
			<div id="search-mobile" onClick={handleSearchClick}>
				<span className="search-icon"></span>
			</div>
			<Search />
			<div id="shop-cart" onClick={handleShowCartClick}>
				<label>
					<div className="modal-trigger shop-cart-trigger" id="cart-trigger">
						<span className="cart-icon"></span>
						<span className="notif-elements" id="menu-cart-items">
							{totalItems}
						</span>
						<span className="mainmenu-text">Carrito</span>
					</div>
				</label>
			</div>
			<AccountMenu />
		</div>
	);
}
