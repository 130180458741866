import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { getMatchingItemsBlank } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleVersions = createSlice({
	name: "vehicleVersions",
	initialState,
	reducers: {
		requestVehicleVersionsList(state) {
			state.isLoading = true;
		},
		receiveVehicleVersionsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadVehicleVersionsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleVersionsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleVersionValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleVersionItem(state, {payload}) {
			if (payload.selectedItem?.Name != null) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Versión",
					label: payload.selectedItem.Name,
					value: payload.selectedItem.ID,
				});
			}
			state.selectedItem = payload.selectedItem;
		},
		clearVehicleVersionsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleVersionValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = action.payload.renderInput;
		},
		clearVehicleVersionData: () => initialState,
	},
});

export const {
	requestVehicleVersionsList,
	receiveVehicleVersionsList,
	loadVehicleVersionsSuggestionsBegin,
	maybeUpdateVehicleVersionsSuggestions,
	updateVehicleVersionValue,
	updateVehicleVersionItem,
	clearVehicleVersionsSuggestions,
	clearVehicleVersionValue,
	clearVehicleVersionData,
} = vehicleVersions.actions;

const shouldFetchVehicleVersions = (state, year) => {
	const yearItem = state.vehicleYears.selectedItem;
	if (state.vehicleVersions.list == null) {
		if (yearItem != null) {
			return true;
		}
		return false;
	}
	if (yearItem?.anio !== year) {
		return true;
	}
	return false;
};
export const fetchVehicleVersionsIfNeeded = (year) => async (
	dispatch,
	getState
) => {
	const theState = getState();
	if (shouldFetchVehicleVersions(theState, year)) {
		return dispatch(
			fetchVehicleVersions(
				theState.vehicleBrands.selectedItem.ID,
				theState.vehicleModels.selectedItem.ID,
				year
			)
		);
	}
};
export const loadVehicleVersionsSuggestions = (value) => async (
	dispatch,
	getState
) => {
	dispatch(loadVehicleVersionsSuggestionsBegin());
	const theState = getState();
	dispatch(
		fetchVehicleVersionsIfNeeded(theState.vehicleYears.selectedItem.anio + "")
	);
	setTimeout(() => {
		dispatch(
			maybeUpdateVehicleVersionsSuggestions({
				suggestions: getMatchingItemsBlank(theState.vehicleVersions, value),
				value,
			})
		);
	});
};
export const fetchVehicleVersions = (brandId, modelId, year) => async (
	dispatch
) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestVehicleVersionsList());
			let responseStatus = 0;
			const url =
				BASE_API_URL +
				"/api/FamilyFriends/Vehiculos/Buscar/Versiones?marca=" +
				brandId +
				"&modelo=" +
				modelId +
				"&anio=" +
				year;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject("Ocurrió un error");
					}
				})
				.then((json) => {
					dispatch(
						receiveVehicleVersionsList({ list: json, renderInput: true })
					);
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default vehicleVersions.reducer;
