import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import qs from "qs";
import {
	fetchActivation,
	toggleShowActivation,
} from "features/account/activationSlice";

export default function AccountActivation(props) {
	const dispatch = useDispatch();
	// Selectors
	const { checked, authenticated } = useSelector((state) => state.session);
	// Handlers
	// Effect
	useEffect(() => {
		function handleActivation(userId) {
			dispatch(toggleShowActivation({ isOpen: false }));
			dispatch(fetchActivation(userId));
		}
		if (props.location.search !== "") {
			const search = qs.parse(props.location.search.substring(1));
			if (search.key != null) {
				handleActivation(search.key);
			}
		}
	}, [dispatch, props.location.search]);
	// Redirect home if authenticated
	if (checked && authenticated) {
		return (
			<Redirect
				to={{ pathname: "/", state: { from: props.location.pathname } }}
			/>
		);
	}
	// Redirect to Partner Login for contipartner activation
	if (props.location.pathname === "/partnersactivacion") {
		return (
			<Redirect
				to={{
					pathname: "/partners",
					state: { from: props.location.pathname },
				}}
			/>
		);
	}
	return (
		<Redirect
			to={{ pathname: "/login", state: { from: props.location.pathname } }}
		/>
	);
}
