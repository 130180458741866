import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { getMatchingItemsTireSize } from "features/functions";
import { setVehicleType } from "./vehicleTypeSlice";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	vehicleType: "0",
};

const sizeWidthDiameters = createSlice({
	name: "sizeWidthDiameters",
	initialState,
	reducers: {
		requestWidthDiametersList(state) {
			state.isLoading = true;
		},
		receiveWidthDiametersList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		setSizeVehicleType(state, action) {
			state.vehicleType = action.payload.vehicleType;
		},
		loadWidthDiametersSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateWidthDiametersSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateWidthDiameterValue(state, action) {
			state.value = action.payload.value;
		},
		updateWidthDiameterItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem?.Value != null) {
				if (selectedItem.IsSae) {
					ReactGA.event({
						category: "Medida SAE",
						action: "Seleccionar Diametro",
						label: selectedItem.Value + "",
						value: selectedItem.Value,
					});
				} else {
					ReactGA.event({
						category: "Medida",
						action: "Seleccionar AnchoSección",
						label: selectedItem.Value + "",
						value: selectedItem.Value,
					});
				}
			}
			state.selectedItem = selectedItem;
		},
		clearWidthDiametersSuggestions(state) {
			state.suggestions = [];
		},
		clearWidthDiameterValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
		clearWidthDiameterData: () => initialState,
	},
});

export const {
	requestWidthDiametersList,
	receiveWidthDiametersList,
	setSizeVehicleType,
	loadWidthDiametersSuggestionsBegin,
	maybeUpdateWidthDiametersSuggestions,
	updateWidthDiameterValue,
	updateWidthDiameterItem,
	clearWidthDiametersSuggestions,
	clearWidthDiameterValue,
	clearWidthDiameterData,
} = sizeWidthDiameters.actions;

const shouldFetchWidthDiameters = (state) => {
	if (state.sizeWidthDiameters.list == null) {
		return true;
	}
	return false;
};
export const fetchWidthDiametersIfNeeded = () => async (dispatch, getState) => {
	let theState = getState();
	if (theState.vehicleType.value !== theState.sizeWidthDiameters.vehicleType) {
		dispatch(setVehicleType(theState.vehicleType.value));
		return dispatch(fetchWidthDiameters(theState.vehicleType.value));
	}
	const shouldFetch = shouldFetchWidthDiameters(theState);
	if (shouldFetch) {
		theState = getState();
		return dispatch(
			fetchWidthDiameters(theState.sizeWidthDiameters.vehicleType)
		);
	}
};
export const loadWidthDiametersSuggestions = (value) => async (
	dispatch,
	getState
) => {
	dispatch(loadWidthDiametersSuggestionsBegin());
	dispatch(fetchWidthDiametersIfNeeded());
	const theState = getState();
	setTimeout(() => {
		dispatch(
			maybeUpdateWidthDiametersSuggestions({
				suggestions: getMatchingItemsTireSize(
					theState.sizeWidthDiameters,
					value
				),
				value,
			})
		);
	});
};
export const fetchWidthDiameters = (dept) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestWidthDiametersList());
			let responseStatus = 0;
			return fetch(
				BASE_API_URL + "/api/FamilyFriends/Medidas/?depto=" + dept,
				options
			)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject("Ocurrió un error");
					}
				})
				.then((json) => {
					dispatch(receiveWidthDiametersList({ list: json }));
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default sizeWidthDiameters.reducer;
