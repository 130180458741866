import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpenTerms: false,
	isOpenDealers: false,
};

const footer = createSlice({
	name: "footer",
	initialState,
	reducers: {
		toggleShowTerms(state, action) {
			state.isOpenTerms = action.payload.isOpenTerms;
		},
		toggleShowDealers(state, action) {
			state.isOpenDealers = action.payload.isOpenDealers;
		},
	},
});

export const { toggleShowTerms, toggleShowDealers } = footer.actions;

export default footer.reducer;
