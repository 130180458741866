import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import { connectRouter } from "connected-react-router";
import accountReducer from "./account/accountSlice";
import accountCardAddSlice from "./account/accountCardAddSlice";
import accountCardEditSlice from "./account/accountCardEditSlice";
import accountCardsSlice from "./account/accountCardsSlice";
import activationReducer from "./account/activationSlice";
import addCouponReducer from "./account/addCouponSlice";
import alertReducer from "./alerts/alertsSlice";
import bannersReducer from "./banners/bannersSlice";
import cartReducer from "./cart/cartSlice";
import contactReducer from "./contact/contactSlice";
import dealerShipAddressReducer from "./cart/dealerShipAddressSlice";
import footerReducer from "./account/footerSlice";
import highlightsTiresReducer from "./tireResults/highlightsTiresSlice";
import loginReducer from "./account/loginSlice";
import ordersReducer from "./orders/ordersSlice";
import orderConfirmationReducer from "./orders/orderConfirmationSlice";
import paymentCardReducer from "./cart/paymentCardSlice";
import registerReducer from "./account/registerSlice";
import resetPasswordReducer from "./account/resetPasswordSlice";
import searchReducer from "./tireFinder/searchSlice";
import searchTiresReducer from "./tireResults/searchTiresSlice";
import sendConfirmationReducer from "./account/confirmationSlice";
import sizeCanvasReducer from "./tireFinder/sizeCanvasSlice";
import sizeWidthDiametersReducer from "./tireFinder/sizeWidthDiametersSlice";
import sizeRatiosReducer from "./tireFinder/sizeRatiosSlice";
import sizeRimsReducer from "./tireFinder/sizeRimsSlice";
import sizeTiresReducer from "./tireResults/sizeTiresSlice";
import sizeSaeWidthsReducer from "./tireFinder/sizeSaeWidthsSlice";
import sizeSaeRimsReducer from "./tireFinder/sizeSaeRimsSlice";
import sizeSaeTiresReducer from "./tireResults/sizeSaeTiresSlice";
import vehicleTypeReducer from "./tireFinder/vehicleTypeSlice";
import vehicleTiresReducer from "./tireResults/vehicleTiresSlice";
import vehicleBrandsReducer from "./tireFinder/vehicleBrandsSlice";
import vehicleModelsReducer from "./tireFinder/vehicleModelsSlice";
import vehicleYearsReducer from "./tireFinder/vehicleYearsSlice";
import vehicleVersionsReducer from "./tireFinder/vehicleVersionsSlice";

const createRootReducer = (history) =>
	combineReducers({
		account: accountReducer,
		accountCardAdd: accountCardAddSlice,
		accountCardEdit: accountCardEditSlice,
		accountCards: accountCardsSlice,
		activation: activationReducer,
		addCoupon: addCouponReducer,
		alert: alertReducer,
		banners: bannersReducer,
		cart: cartReducer,
		contact: contactReducer,
		dealerShipAddress: dealerShipAddressReducer,
		footer: footerReducer,
		highlightsTires: highlightsTiresReducer,
		login: loginReducer,
		orders: ordersReducer,
		orderConfirmation: orderConfirmationReducer,
		paymentCard: paymentCardReducer,
		register: registerReducer,
		resetPassword: resetPasswordReducer,
		router: connectRouter(history),
		search: searchReducer,
		searchTires: searchTiresReducer,
		sendConfirmation: sendConfirmationReducer,
		session: sessionReducer,
		sizeCanvas: sizeCanvasReducer,
		sizeWidthDiameters: sizeWidthDiametersReducer,
		sizeRatios: sizeRatiosReducer,
		sizeRims: sizeRimsReducer,
		sizeTires: sizeTiresReducer,
		sizeSaeWidths: sizeSaeWidthsReducer,
		sizeSaeRims: sizeSaeRimsReducer,
		sizeSaeTires: sizeSaeTiresReducer,
		vehicleType: vehicleTypeReducer,
		vehicleTires: vehicleTiresReducer,
		vehicleBrands: vehicleBrandsReducer,
		vehicleModels: vehicleModelsReducer,
		vehicleYears: vehicleYearsReducer,
		vehicleVersions: vehicleVersionsReducer,
	});
export default createRootReducer;
