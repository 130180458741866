import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { getMatchingItemsBlank } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleModels = createSlice({
	name: "vehicleModels",
	initialState,
	reducers: {
		requestVehicleModelsList(state) {
			state.isLoading = true;
		},
		receiveVehicleModelsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadVehicleModelsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleModelsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleModelValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleModelItem(state, {payload}) {
			if (payload.selectedItem?.Name != null) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Modelo",
					label: payload.selectedItem.Name,
					value: payload.selectedItem.ID,
				});
			}
			state.selectedItem = payload.selectedItem;
		},
		clearVehicleModelsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleModelValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = action.payload.renderInput;
		},
		clearVehicleModelData: () => initialState,
	},
});

export const {
	requestVehicleModelsList,
	receiveVehicleModelsList,
	loadVehicleModelsSuggestionsBegin,
	maybeUpdateVehicleModelsSuggestions,
	updateVehicleModelValue,
	updateVehicleModelItem,
	clearVehicleModelsSuggestions,
	clearVehicleModelValue,
	clearVehicleModelData,
} = vehicleModels.actions;

const shouldFetchVehicleModels = (state, brandId) => {
	const brand = state.vehicleBrands.selectedItem;
	if (state.vehicleModels.list == null) {
		if (brand != null) {
			return true;
		}
		return false;
	}
	if (brand?.ID !== brandId) {
		return true;
	}
	return false;
};
export const fetchVehicleModelsIfNeeded = (brandId) => async (
	dispatch,
	getState
) => {
	if (shouldFetchVehicleModels(getState(), brandId)) {
		return dispatch(fetchVehicleModels(brandId));
	}
};
export const loadVehicleModelsSuggestions = (value) => async (
	dispatch,
	getState
) => {
	dispatch(loadVehicleModelsSuggestionsBegin());
	const theState = getState();
	dispatch(fetchVehicleModelsIfNeeded(theState.vehicleBrands.selectedItem.ID));
	setTimeout(() => {
		dispatch(
			maybeUpdateVehicleModelsSuggestions({
				suggestions: getMatchingItemsBlank(theState.vehicleModels, value),
				value,
			})
		);
	});
};
export const fetchVehicleModels = (brandId) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestVehicleModelsList());
			let responseStatus = 0;
			const url =
				BASE_API_URL +
				"/api/FamilyFriends/Vehiculos/Buscar/Modelos?marca=" +
				brandId;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject("Ocurrió un error");
					}
				})
				.then((json) => {
					dispatch(receiveVehicleModelsList({ list: json, renderInput: true }));
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default vehicleModels.reducer;
