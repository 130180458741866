import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import {
	fetchSendConfirmation,
	setConfirmationErrorMsg,
	clearConfirmationData,
} from "features/account/confirmationSlice";
import Loader from "components/Loader";
import AlertModal from "../alert/AlertModal";
import bgImage from "images/login-bg-2.jpg";
import bgImagePartner from "images/login-partner-bg.jpg";
import { USER_TYPE } from "features/constants";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

export default function Confirmation(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg } = useSelector(
		(state) => state.sendConfirmation
	);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	// Refs
	const usernameRef = useRef();
	// Params from URL
	const { params } = props.match;
	const userType =
		params?.type === "partner" ? USER_TYPE.PARTNER : USER_TYPE.FAMILY;
	console.log("Confirmation", { userType, params });
	useEffect(() => usernameRef.current && usernameRef.current.focus(), []);
	// Redirect
	if (checked && authenticated) {
		return (
			<Redirect
				to={{ pathname: "/", state: { from: props.location.pathname } }}
			/>
		);
	}
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setConfirmationErrorMsg({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handleCancelClick() {
		dispatch(clearConfirmationData());
		if (userType === USER_TYPE.PARTNER) {
			ReactGA.send({ hitType: "pageview", page: "/partners" });
			props.history.push("/partners");
		} else {
			ReactGA.send({ hitType: "pageview", page: "/login" });
			props.history.push("/login");
		}
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		}
		if (valid) {
			dispatch(fetchSendConfirmation(usrname, params?.type ?? "family"));
		} else {
			dispatch(setConfirmationErrorMsg({ errorMsg: errorMessages }));
		}
	}
	let errors = null;
	if (errorMsg != null) {
		errors = (
			<div className="error-list">
				<ul>
					{renderError(errorMsg.error)}
					{renderError(errorMsg.usrname)}
				</ul>
			</div>
		);
	}
	let content;
	if (isLoading) {
		content = (
			<form>
				<div
					id="login-content"
					style={{
						backgroundImage: `url(${
							userType === USER_TYPE.PARTNER ? bgImagePartner : bgImage
						})`,
					}}
				>
					<div>
						<h1>
							ContiShop <br />
							{userType === USER_TYPE.PARTNER
								? "ContiPartners"
								: "Family & Friends"}
						</h1>
						<p>
							{userType === USER_TYPE.PARTNER
								? `La tienda en línea para partners de Continental Tire`
								: `La tienda en línea para amigos y familiares de los empleados de Continental Tire`}
						</p>
						<Loader msg="Enviando..." />
					</div>
				</div>
			</form>
		);
	} else {
		content = (
			<form onSubmit={handleSubmit}>
				<div
					id="login-content"
					style={{
						backgroundImage: `url(${
							userType === USER_TYPE.PARTNER ? bgImagePartner : bgImage
						})`,
					}}
				>
					<div>
						<h1>
							ContiShop <br />
							{userType === USER_TYPE.PARTNER
								? "ContiPartners"
								: "Family & Friends"}
						</h1>
						<p>
							Si no recibiste el correo de confirmación de registro puedes
							solicitarlo de nuevo.
						</p>
						<div className="form-a">
							<div>
								<div>
									<label>Correo</label>
									<input
										type="text"
										value={usrname}
										autoComplete="username"
										placeholder="Correo electrónico"
										ref={usernameRef}
										onChange={handleUsrnameChange}
									></input>
								</div>
							</div>
						</div>
						{errors}
						<div>
							<button type="submit" className="button main">
								Solicitar
							</button>
						</div>
						<div>
							<button
								type="reset"
								className="button"
								onClick={handleCancelClick}
							>
								Cancelar
							</button>
						</div>
					</div>
				</div>
			</form>
		);
	}
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			{content}
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<AlertModal />
		</div>
	);
}
