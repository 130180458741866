import React from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import AlertModal from "../alert/AlertModal";
import ActivationModal from "components/account/ActivationModal";
import bgImage from "images/contishop-familyfriends-home-bg.jpg";
import logo from "images/contishop-logo.jpg";

export default function Home() {
	// Handlers
	function handleFamily() {
		ReactGA.send({ hitType: "pageview", page: "/login" });
	}
	function handlePartners() {
		ReactGA.send({ hitType: "pageview", page: "/partners" });
	}
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			<div id="login-content" style={{ backgroundImage: `url(${bgImage})` }}>
				<div className="home">
					<div>
						<img src={logo} alt="ContiShop" />
					</div>
					<div className="home-options">
						<div>
							<Link to={"/login"} onClick={handleFamily}>
								<button type="button" className="button main">
									Family &amp; Friends
								</button>
							</Link>
						</div>
						<div>
							<Link to={"/partners"} onClick={handlePartners}>
								<button type="button" className="button main">
									Conti Partners
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<ActivationModal />
			<AlertModal />
		</div>
	);
}
