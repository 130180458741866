import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
	hide: false,
};
const searchTires = createSlice({
	name: "searchTires",
	initialState,
	reducers: {
		requestSearchResultsTiresList(state) {
			state.isLoading = true;
		},
		receiveSearchResultsTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearSearchResultsTiresList(state) {
			state.list = null;
		},
		hideSearchResultsList(state, action) {
			state.hide = action.payload.hide;
		},
		clearSearchResultData: () => initialState,
	},
});

export const {
	requestSearchResultsTiresList,
	receiveSearchResultsTiresList,
	clearSearchResultsTiresList,
	hideSearchResultsList,
	clearSearchResultData,
} = searchTires.actions;

const shouldFetchSearchResultsTiresList = (state) => {
	const tires = state.searchTires;
	const search = state.search;
	if (tires.list == null) {
		return true;
	}
	if (tires.list.Id !== search.selectedItem.Id) {
		return true;
	}
	return false;
};

export const fetchSearchResultsTireListIfNeeded = () => async (
	dispatch,
	getState
) => {
	const theState = getState();
	if (shouldFetchSearchResultsTiresList(theState)) {
		return dispatch(
			fetchSearchResultsTiresList(theState.search.selectedItem.Id)
		);
	}
};

export const fetchSearchResultsTiresList = (id) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestSearchResultsTiresList());
			const url = BASE_API_URL + "/api/FamilyFriends/Productos/?id=" + id;
			let responseStatus = 0;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject("Ocurrió un error");
					}
				})
				.then((json) => {
					dispatch(receiveSearchResultsTiresList({ list: json }));
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default searchTires.reducer;
