import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "../account/loginSlice";

const initialState = {
	isLoading: false,
	list: [],
};

const banners = createSlice({
	name: "banners",
	initialState,
	reducers: {
		requestBanners(state) {
			state.isLoading = true;
		},
		receiveBanners(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearBannersData: () => initialState,
	},
});

export const { requestBanners, receiveBanners, clearBannersData } =
	banners.actions;

export const fetchBannersList = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestBanners());
			let responseStatus = 0;
			return fetch(BASE_API_URL + "/api/FamilyFriends/Banners", options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return response.text().then((error) => Promise.reject(error));
					}
				})
				.then((json) => {
					dispatch(receiveBanners({ list: json }));
				})
				.catch(() => {
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default banners.reducer;
