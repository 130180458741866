import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
};
const vehicleTires = createSlice({
	name: "vehicleTires",
	initialState,
	reducers: {
		requestVehicleTiresList(state) {
			state.isLoading = true;
		},
		receiveVehicleTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearVehicleTiresData: () => initialState,
	},
});

export const {
	requestVehicleTiresList,
	receiveVehicleTiresList,
	clearVehicleTiresData,
} = vehicleTires.actions;

const shouldFetchVehicleTiresList = (state) => {
	const selectedBrand = state.vehicleBrands.selectedItem;
	const selectedModel = state.vehicleModels.selectedItem;
	const selectedYear = state.vehicleYears.selectedItem;
	const selectedVersion = state.vehicleVersions.selectedItem;
	const tires = state.vehicleTires.list;
	if (selectedVersion == null) {
		return false;
	}
	if (tires == null) {
		return true;
	}
	if (
		selectedBrand?.ID !== tires.BrandId ||
		selectedModel?.ID !== tires.ModelId ||
		selectedYear?.anio !== tires.Year ||
		selectedVersion?.ID !== tires.VersionId
	) {
		return true;
	}
	return false;
};

export const fetchVehicleTiresListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchVehicleTiresList(theState)) {
			return dispatch(
				fetchVehicleTiresList(
					theState.vehicleBrands.selectedItem.ID,
					theState.vehicleModels.selectedItem.ID,
					theState.vehicleYears.selectedItem.anio,
					theState.vehicleVersions.selectedItem.ID
				)
			);
		}
	};

export const fetchVehicleTiresList =
	(brand, model, year, version) => async (dispatch) => {
		sessionService
			.loadSession()
			.then((session) => {
				const headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
				const options = {
					method: "GET",
					headers: headers,
				};
				dispatch(requestVehicleTiresList());
				const url =
					BASE_API_URL +
					"/api/FamilyFriends/Vehiculos/Buscar?marca=" +
					brand +
					"&modelo=" +
					model +
					"&anio=" +
					year +
					"&version=" +
					version;
				let responseStatus = 0;
				return fetch(url, options)
					.then((response) => {
						responseStatus = response.status;
						if (response.ok) {
							return response.json();
						} else {
							return Promise.reject("Ocurrió un error");
						}
					})
					.then((json) => {
						ReactGA.event({
							category: "Llantas",
							action: "Vehículo",
							label: json.Name + " " + json.Year,
							value: json.Id,
						});
						dispatch(receiveVehicleTiresList({ list: json }));
					})
					.catch((error) => {
						// TODO: fetch error actions
						// If 401 Unauthorized login failed
						if (responseStatus === 401) {
							dispatch(runUnauthorizedActions());
						}
					});
			})
			.catch((err) => console.log("Error", err));
	};

export default vehicleTires.reducer;
