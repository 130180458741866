import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "features/reducers";
import { routerMiddleware } from "connected-react-router";

const debug = process.env.REACT_APP_REDUX_DEBUG === "true";

const initStore = (history) =>
	configureStore({
		reducer: createRootReducer(history),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(routerMiddleware(history)),
		devTools: debug,
	});
	export default initStore;