import React from "react";
import MainMenu from "./MainMenu";
import HomeContent from "./StoreContent";
import CartModal from "../cart/CartModal";
import OrderConfirmationModal from "../order/OrderConfirmationModal";
import AccountOrders from "../account/AccountOrders";
import OrderModal from "../account/OrderModal";
import AddCouponModal from "../account/AddCouponModal";
import AlertModal from "components/alert/AlertModal";
import AccountCards from "components/account/AccountCards";
import AccountCardEdit from "components/account/AccountCardEdit";
import AccountCardDelete from "components/account/AccountCardDelete";
import OpenPayPointsModal from "components/cart/OpenPayPointsModal";

export default function Store() {
	return (
		<div>
			<div id="logo-conti-top">
				<span></span>
			</div>
			<MainMenu />
			<HomeContent />
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<CartModal />
			<OrderConfirmationModal />
			<AccountOrders />
			<AccountCards />
			<AccountCardEdit />
			<AccountCardDelete />
			<OpenPayPointsModal />
			<OrderModal />
			<AddCouponModal />
			<AlertModal />
		</div>
	);
}
