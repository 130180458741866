import React from "react";
import TermsDisclaimer from "./TermsDisclaimer";
import history from "features/history";

export default function Terms() {
	// Handlers
	function handleClick() {
		history.push("/")
	}
	// Render
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			<div id="login-content">
				<article className="terms">
					<TermsDisclaimer />
					<div className="center">
						<div onClick={handleClick} className="button main">Regresar</div>
					</div>
				</article>
			</div>
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
		</div>
	);
}
