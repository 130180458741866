import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "./loginSlice";
import { setAlert, toggleShowAlert } from "../alerts/alertsSlice";

const initialState = {
	isOpen: false,
	isLoading: false,
	limit: null,
	errorMsg: null,
};

const addCoupon = createSlice({
	name: "addCoupon",
	initialState,
	reducers: {
		requestLimit(state) {
			state.isLoading = true;
		},
		receiveLimit(state, action) {
			state.limit = action.payload.limit;
			state.isLoading = false;
		},
		requestAddCoupon(state) {
			state.isLoading = true;
		},
		receiveAddCoupon(state) {
			state.isLoading = false;
		},
		setAddCouponErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		toggleShowAddCoupon(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		clearAddCouponData: () => initialState,
	},
});

export const {
	requestLimit,
	receiveLimit,
	requestAddCoupon,
	receiveAddCoupon,
	setAddCouponErrorMsg,
	toggleShowAddCoupon,
	clearAddCouponData,
} = addCoupon.actions;

export const fetchLimit = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestLimit());
			let responseStatus = 0;
			return fetch(BASE_API_URL + "/api/FamilyFriends/Cuenta/Limite", options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject(
							"El usuario o la contraseña son incorrectos."
						);
					}
				})
				.then((json) => {
					dispatch(receiveLimit({ limit: json }));
				})
				.catch(() => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchAddCoupon = (data) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					cupon: data.coupon,
				}),
			};
			dispatch(requestAddCoupon());
			let responseStatus = 0;
			return fetch(
				BASE_API_URL + "/api/FamilyFriends/Cuenta/CargaCupon",
				options
			)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.text();
					} else {
						return response.text().then((error) => Promise.reject(error));
					}
				})
				.then(() => {
					dispatch(
						setAlert({
							alert: {
								title: "¡Excelente!",
								description: "Se activó el cupón con éxito.",
								details: null,
							},
						})
					);
					dispatch(toggleShowAlert({ isOpen: false }));
					dispatch(fetchLimit());
				})
				.catch((error) => {
					// If 400 Bad Request
					if (responseStatus === 400) {
						const json = JSON.parse(error);
						dispatch(receiveAddCoupon());
						dispatch(
							setAddCouponErrorMsg({ errorMsg: { coupon: json.Message } })
						);
					}
					// If 404 Not Found
					if (responseStatus === 404) {
						dispatch(receiveAddCoupon());
						dispatch(
							setAddCouponErrorMsg({
								errorMsg: { coupon: "No se pudo encontrar el cupón." },
							})
						);
					}
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};
export const fetchAddPartnerCoupon = (data) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					cupon: data.coupon,
				}),
			};
			dispatch(requestAddCoupon());
			let responseStatus = 0;
			return fetch(
				BASE_API_URL + "/api/FamilyFriends/Cuenta/CargaCuponPartner",
				options
			)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.text();
					} else {
						return response.text().then((error) => Promise.reject(error));
					}
				})
				.then(() => {
					dispatch(
						setAlert({
							alert: {
								title: "¡Excelente!",
								description: "Se activó el cupón con éxito.",
								details: null,
							},
						})
					);
					dispatch(toggleShowAlert({ isOpen: false }));
					dispatch(fetchLimit());
				})
				.catch((error) => {
					// If 400 Bad Request
					if (responseStatus === 400) {
						const json = JSON.parse(error);
						dispatch(receiveAddCoupon());
						dispatch(
							setAddCouponErrorMsg({ errorMsg: { coupon: json.Message } })
						);
					}
					// If 404 Not Found
					if (responseStatus === 404) {
						dispatch(receiveAddCoupon());
						dispatch(
							setAddCouponErrorMsg({
								errorMsg: { coupon: "No se pudo encontrar el cupón." },
							})
						);
					}
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default addCoupon.reducer;
