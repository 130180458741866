import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import history from "../history";
import { BASE_API_URL } from "../constants";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";

const initialState = {
	isLoading: false,
	errorMsg: null,
};
const register = createSlice({
	name: "register",
	initialState,
	reducers: {
		requestRegistration(state) {
			state.isLoading = true;
		},
		completeRegistration(state) {
			state.isLoading = false;
		},
		setRegistrationErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearRegisterData: () => initialState,
	},
});

export const {
	requestRegistration,
	completeRegistration,
	setRegistrationErrorMsg,
	clearRegisterData,
} = register.actions;

export const fetchRegister = (data) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			email: data.usrname,
			password: data.pwd,
			name: data.name,
			lastname: data.lastName,
			mothername: data.motherName,
			gender: data.gender,
			birthdate: data.birthdate,
			phone: data.phone,
			subscribe: data.subscribe,
			cupon: data.coupon,
		}),
	};
	const url = BASE_API_URL + "/api/FamilyFriends/Cuenta/Registro";
	dispatch(requestRegistration());
	let responseStatus = 0;
	return fetch(url, options)
		.then((response) => {
			responseStatus = response.status;
			if (response.ok) {
				return response.text();
			} else {
				return response.text().then((text) => Promise.reject(text));
			}
		})
		.then(() => {
			ReactGA.event({
				category: "Cuenta",
				action: "Registro Exitoso",
			});
			dispatch(
				setAlert({
					alert: {
						title: "¡Excelente!",
						description:
							"Se ha completado el registro. Recibirás un correo de confirmación para activar tu cuenta.",
						details: null,
					},
				})
			);
			dispatch(completeRegistration());
			dispatch(toggleShowAlert({ isOpen: false }));
			dispatch(setRegistrationErrorMsg({ errorMsg: null }));
			history.push("/login")
		})
		.catch((error) => {
			ReactGA.event({
				category: "Cuenta",
				action: "Registro Fallido",
			});
			// TODO: fetch error actions
			// If 404 Not found
			if (responseStatus === 404) {
				dispatch(
					setRegistrationErrorMsg({
						errorMsg: { coupon: "Debes escribir un cupón válido" },
					})
				);
				dispatch(
					setAlert({
						alert: {
							title: "¡Lo sentimos!",
							description:
								"El cupón que escribiste no es válido. Se requiere un cupón válido para poderse registrar.",
							details: error.Message,
						},
					})
				);
			} else if (error.includes("duplicate key")) {
				const json = JSON.parse(error);
				dispatch(
					setRegistrationErrorMsg({ errorMsg: { usrname: json.Message } })
				);
				dispatch(
					setAlert({
						alert: {
							title: "¡Lo sentimos!",
							description: "Este correo electrónico ya está registrado.",
							details:
								"Si no recuerdas tu contraseña puedes solicitar una nueva.",
						},
					})
				);
			} else {
				if (!error.toLowerCase().includes("modelstate")) {
					const json = JSON.parse(error);
					dispatch(
						setRegistrationErrorMsg({ errorMsg: { coupon: json.Message } })
					);
					dispatch(
						setAlert({
							alert: {
								title: "¡Lo sentimos!",
								description: "No se ha podido completar el registro.",
								details: json.Message,
							},
						})
					);
				} else {
					dispatch(
						setAlert({
							alert: {
								title: "¡Lo sentimos!",
								description:
									"No se ha podido completar el registro. Verifica los datos y vuelve a intentar.",
							},
						})
					);
				}
			}
			dispatch(completeRegistration());
			dispatch(toggleShowAlert({ isOpen: false }));
		});
};

export default register.reducer;
