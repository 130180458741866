import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
	hide: false,
};
const highlightsTires = createSlice({
	name: "highlightsTires",
	initialState,
	reducers: {
		requestHighlightsTiresList(state) {
			state.isLoading = true;
		},
		receiveHighlightsTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearHighlightsTiresList(state) {
			state.list = null;
		},
		hideHighlightsTiresList(state, action) {
			state.hide = action.payload.hide;
		},
		clearHighlightsData: () => initialState,
	},
});

export const {
	requestHighlightsTiresList,
	receiveHighlightsTiresList,
	clearHighlightsTiresList,
	hideHighlightsTiresList,
	clearHighlightsData,
} = highlightsTires.actions;

const shouldFetchHighlightsTiresList = (state) => {
	if (state.highlightsTires.list == null) {
		return true;
	}
	return false;
};

export const fetchHighlightsTiresListIfNeeded = () => async (dispatch, getState) => {
	const theState = getState();
	if (shouldFetchHighlightsTiresList(theState)) {
		return dispatch(fetchHighlightsTiresList());
	}
};

export const fetchHighlightsTiresList = () => async (
	dispatch
) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				method: "GET",
				headers: headers,
			};
			dispatch(requestHighlightsTiresList());
			const url = BASE_API_URL + "/api/FamilyFriends/Productos/Home";
			let responseStatus = 0;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return Promise.reject("Ocurrió un error");
					}
				})
				.then((json) => {
					dispatch(receiveHighlightsTiresList({ list: json }));
				})
				.catch((error) => {
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default highlightsTires.reducer;
