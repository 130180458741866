import React from "react";

export default function SliderItem(props) {
	function renderTitle() {
		if (props.name != null){
			return (
				<p className="slide-title">{props.name}</p>
			)
		}
		else {
			return null
		}
	}
	function renderText() {
		if (props.text!=null) {
			return (
				<p className="slide-text">{props.text}</p>
			)
		}
		else 
			return null
	}
	return (
		<li>
		<div className="conti-slide-content" style={ { backgroundImage: "url('"+props.image+"')" } }>
				{renderTitle()}
				{renderText()}
		</div>
</li>

	)
}