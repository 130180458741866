import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Modal from "react-modal";
import ReactGA from "react-ga4";
import { sub } from "date-fns";
import DatePicker from "react-datepicker";
import Loader from "components/Loader";
import AlertModal from "components/alert/AlertModal";
import bgImage from "images/login-bg-2.jpg";
import { validatePwd, validateMail, validatePhone } from "features/functions";
import {
	clearRegisterData,
	fetchRegister,
	setRegistrationErrorMsg,
} from "features/account/registerSlice";
import TermsDisclaimer from "./TermsDisclaimer";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

Modal.setAppElement("#root");

export default function Register(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg } = useSelector((state) => state.register);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	const [pwd, setPwd] = useState("");
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [motherName, setMotherName] = useState("");
	const [phone, setPhone] = useState("");
	const [coupon, setCoupon] = useState("");
	const [gender, setGender] = useState(null);
	const [birthdate, setBirthdate] = useState(null);
	const [accept, setAccept] = useState(true);
	const [subscribe, setSubscribe] = useState(true);
	const [defaultBirthdate, setDefaultBirthdate] = useState(true);
	// Refs
	const usernameRef = useRef();
	useEffect(() => usernameRef.current && usernameRef.current.focus(), []);
	// Redirect
	if (checked && authenticated) {
		return (
			<Redirect
				to={{ pathname: "/", state: { from: props.location.pathname } }}
			/>
		);
	}
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handlePwdChange(e) {
		if (errorMsg != null && errorMsg.pwd != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, pwd: null, error: null },
				})
			);
		}
		setPwd(e.target.value);
	}
	function handleNameChange(e) {
		if (errorMsg != null && errorMsg.name != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, name: null, error: null },
				})
			);
		}
		setName(e.target.value);
	}
	function handleLastNameChange(e) {
		if (errorMsg != null && errorMsg.lastName != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, lastName: null, error: null },
				})
			);
		}
		setLastName(e.target.value);
	}
	function handleMotherNameChange(e) {
		setMotherName(e.target.value);
	}
	function handlePhoneChange(e) {
		if (errorMsg != null && errorMsg.phone != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, phone: null, error: null },
				})
			);
		}
		setPhone(e.target.value);
	}
	function handleCouponChange(e) {
		if (errorMsg != null && errorMsg.coupon != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, coupon: null, error: null },
				})
			);
		}
		setCoupon(e.target.value);
	}
	function handleGenderChange(e) {
		if (errorMsg != null && errorMsg.gender != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, gender: null, error: null },
				})
			);
		}
		setGender(e.target.value);
	}
	function handleDateChange(date) {
		if (errorMsg != null && errorMsg.birthdate != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, birthdate: null, error: null },
				})
			);
		}
		setBirthdate(date);
		setDefaultBirthdate(false);
	}
	function handleTermsChange(e) {
		if (errorMsg != null && errorMsg.accept != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, accept: null, error: null },
				})
			);
		}
		setAccept(e.target.checked);
	}
	function handleSubscriptionChange(e) {
		setSubscribe(e.target.checked);
	}
	function handleCancelClick() {
		dispatch(clearRegisterData());
		ReactGA.send({ hitType: "pageview", page: "/login" });
		props.history.push("/login");
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		} else {
			if (!validateMail(usrname)) {
				errorMessages = {
					...errorMessages,
					usrname: "Debes escribir un correo electrónico válido",
				};
				valid = false;
			}
		}
		if (pwd.trim() === "") {
			errorMessages = {
				...errorMessages,
				pwd: "Debes escribir una contraseña",
			};
			valid = false;
		} else {
			if (!validatePwd(pwd)) {
				errorMessages = {
					...errorMessages,
					pwd: "La contraseña debe tener entre 8 y 16 caracteres y debe contener al menos una Mayúscula, una minúscula, un número y un caracter especial",
				};
				valid = false;
			}
		}
		if (name.trim() === "") {
			errorMessages = { ...errorMessages, name: "Debes escribir tu nombre" };
			valid = false;
		}
		if (lastName.trim() === "") {
			errorMessages = {
				...errorMessages,
				lastName: "Debes escribir tu apellido paterno",
			};
			valid = false;
		}
		if (phone.trim() === "") {
			errorMessages = {
				...errorMessages,
				phone: "Debes escribir un teléfono para poder contactarte",
			};
			valid = false;
		} else {
			if (!validatePhone(phone)) {
				errorMessages = {
					...errorMessages,
					phone: "Debes escribir un teléfono válido",
				};
				valid = false;
			}
		}
		if (defaultBirthdate) {
			errorMessages = {
				...errorMessages,
				birthdate: "Debes seleccionar tu fecha de nacimiento",
			};
			valid = false;
		}
		if (gender == null) {
			errorMessages = {
				...errorMessages,
				gender: "Debes seleccionar el género",
			};
			valid = false;
		}
		if (coupon.trim() === "") {
			errorMessages = {
				...errorMessages,
				coupon: "Para poder registrarte necesitas un cupón válido",
			};
			valid = false;
		}
		if (!accept) {
			errorMessages = {
				...errorMessages,
				accept: "Debes aceptar los términos y condiciones de uso",
			};
			valid = false;
		}
		if (valid) {
			dispatch(
				fetchRegister({
					usrname,
					pwd,
					name,
					lastName,
					motherName,
					phone,
					birthdate,
					gender,
					coupon,
					accept,
					subscribe,
				})
			);
		} else {
			dispatch(setRegistrationErrorMsg({ errorMsg: errorMessages }));
		}
	}
	let errors = null;
	if (errorMsg != null) {
		errors = (
			<div className="error-list">
				<ul>
					{renderError(errorMsg.error)}
					{renderError(errorMsg.usrname)}
					{renderError(errorMsg.pwd)}
					{renderError(errorMsg.name)}
					{renderError(errorMsg.lastName)}
					{renderError(errorMsg.phone)}
					{renderError(errorMsg.birthdate)}
					{renderError(errorMsg.gender)}
					{renderError(errorMsg.coupon)}
					{renderError(errorMsg.accept)}
				</ul>
			</div>
		);
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			return property === "gender" ? { className: "gender" } : {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			return property === "gender"
				? { className: "gender error" }
				: { className: "error" };
		}
		return property === "gender" ? { className: "gender" } : {};
	}
	let content;
	if (isLoading) {
		content = (
			<form>
				<div id="login-content" style={{ backgroundImage: `url(${bgImage})` }}>
					<div className="register">
						<h1>Registro</h1>
						<Loader msg="Por favor espere..." />
					</div>
				</div>
			</form>
		);
	} else {
		content = (
			<form onSubmit={handleSubmit}>
				<div id="login-content" style={{ backgroundImage: `url(${bgImage})` }}>
					<div className="register">
						<h1>Registro</h1>
						<div className="form-a two">
							<div>
								<div {...getFieldProps("usrname")}>
									<label>Correo Electrónico *</label>
									<input
										type="text"
										ref={usernameRef}
										placeholder="Correo Electrónico"
										value={usrname}
										onChange={handleUsrnameChange}
										autoComplete="email"
									></input>
								</div>
								<div {...getFieldProps("pwd")}>
									<label>Contraseña *</label>
									<input
										type="password"
										value={pwd}
										onChange={handlePwdChange}
										placeholder="Contraseña"
										autoComplete="new-password"
									></input>
								</div>
							</div>
						</div>
						<div className="form-a two">
							<div>
								<div {...getFieldProps("name")}>
									<label>Nombre *</label>
									<input
										type="text"
										placeholder="Nombre"
										value={name}
										onChange={handleNameChange}
										autoComplete="given-name"
									></input>
								</div>
								<div {...getFieldProps("lastName")}>
									<label>Apellido Paterno *</label>
									<input
										type="text"
										placeholder="Apellido Paterno"
										value={lastName}
										onChange={handleLastNameChange}
										autoComplete="additional-name"
									></input>
								</div>
							</div>
						</div>
						<div className="form-a two">
							<div>
								<div>
									<label>Apellido Materno</label>
									<input
										type="text"
										placeholder="Apellido Materno"
										value={motherName}
										onChange={handleMotherNameChange}
										autoComplete="family-name"
									></input>
								</div>
								<div {...getFieldProps("phone")}>
									<label>Teléfono *</label>
									<input
										type="text"
										placeholder="Teléfono"
										value={phone}
										onChange={handlePhoneChange}
										autoComplete="tel"
									></input>
								</div>
							</div>
						</div>
						<div className="form-a three">
							<div>
								<div {...getFieldProps("birthdate")}>
									<label>Fecha de Nacimiento *</label>
									<DatePicker
										selected={birthdate}
										onChange={handleDateChange}
										minDate={sub(new Date(), { years: 100 })}
										maxDate={sub(new Date(), { years: 18 })}
										showYearDropdown
										showMonthDropdown
										dropdownMode="select"
										calendarClassName="conti-calendar"
										shouldCloseOnSelect={true}
										strictParsing={true}
										placeholderText="Fecha de Nacimiento"
									/>
								</div>
								<div {...getFieldProps("gender")}>
									<label>Género</label>
									<ul>
										<li>
											<input
												type="radio"
												value="M"
												name="gender-type"
												id="gender-type-male"
												checked={gender === "M"}
												onChange={handleGenderChange}
											></input>
											<label htmlFor="gender-type-male">
												<span className="icon-male"></span>
											</label>
										</li>
										<li>
											<input
												type="radio"
												value="F"
												name="gender-type"
												id="gender-type-female"
												checked={gender === "F"}
												onChange={handleGenderChange}
											></input>
											<label htmlFor="gender-type-female">
												<span className="icon-female"></span>
											</label>
										</li>
									</ul>
								</div>
								<div {...getFieldProps("coupon")}>
									<label>Cupón *</label>
									<input
										type="text"
										placeholder="Cupón"
										value={coupon}
										onChange={handleCouponChange}
										autoComplete="contishop-unique-coupon-code"
									></input>
								</div>
							</div>
						</div>
						<div className="register-disclaimer">
							<TermsDisclaimer />
						</div>
						<div>
							<div className="check-switch">
								<input
									type="checkbox"
									name="subscribe"
									id="subscribe"
									checked={subscribe === true}
									onChange={handleSubscriptionChange}
								/>
								<label htmlFor="accept">
									<span className="icon-switch"></span> Deseo recibir
									promociones y noticias de Continental Tire de México
								</label>
							</div>
						</div>
						<div>
							<div className="check-switch">
								<input
									type="checkbox"
									name="accept"
									id="accept"
									checked={accept === true}
									onChange={handleTermsChange}
								/>
								<label htmlFor="accept">
									<span className="icon-switch"></span> Acepto términos y
									condiciones del sitio
								</label>
							</div>
						</div>
						{errors}
						<div className="form-submit">
							<button
								type="reset"
								className="button"
								onClick={handleCancelClick}
							>
								Cancelar
							</button>
							<button type="submit" className="button main">
								Registrarme
							</button>
						</div>
					</div>
				</div>
			</form>
		);
	}
	// Render
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			{content}
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<AlertModal />
		</div>
	);
}
