import React from "react";
import { useSelector } from "react-redux";
import SliderItem from "./SliderItem";
import slideFamilyImg from "images/familyfriends-header-3.jpg";
import slidePartnerImg from "images/partners-header.jpg";
import defaultImg from "images/default-header.jpg";
import Loader from "components/Loader";

const sliderDivStyle = {
	height: "100%",
};

export default function Slider() {
	// Selectors
	const { isLoading, list } = useSelector((state) => state.banners);
	const { user } = useSelector((state) => state.session);
	// Render Functions
	function renderLoader() {
		if (isLoading) {
			return <Loader />;
		}
		return null;
	}
	function renderSlides() {
		if (isLoading) {
			return null;
		}
		if (list !== null && list.length >= 1) {
			return (
				<ul>
					<SliderItem image={"images/slider/" + list[0].Imagen} />
				</ul>
			);
		}
		if (user.user != null && user.user.type === 3) {
			return (
				<ul>
					<SliderItem image={slideFamilyImg} />
				</ul>
			);
		}
		if (user.user != null && user.user.type === 4) {
			return (
				<ul>
					<SliderItem image={slidePartnerImg} />
				</ul>
			);
		}
		return (
			<ul>
				<SliderItem image={defaultImg} />
			</ul>
		);
	}
	return (
		<div className="main-slider family" style={sliderDivStyle}>
			<div className="content-box">
				{renderLoader()}
				{renderSlides()}
			</div>
		</div>
	);
}
