import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toggleShowAlert } from "features/alerts/alertsSlice";
import { setAlert } from "features/alerts/alertsSlice";
import { BASE_API_URL } from "features/constants";
import { sessionService } from "redux-react-session";
import { fetchAccountCards, setCardsIsLoading } from "./accountCardsSlice";
import { runUnauthorizedActions } from "./loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	isOpenDelete: false,
	errorMsg: null,
};

const accountCardEdit = createSlice({
	name: "accountCardEdit",
	initialState,
	reducers: {
		requestCardEdit: (state) => {
			state.isLoading = true;
		},
		receiveCardEdit: (state) => {
			state.isLoading = false;
		},
		setCardEditErrorMsg: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
		toggleShowEditCard: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
		toggleShowDeleteCard: (state, action) => {
			state.isOpenDelete = !action.payload.isOpenDelete;
		},
		clearCardEditData: () => initialState,
	},
});

export const {
	requestCardEdit,
	receiveCardEdit,
	setCardEditErrorMsg,
	toggleShowEditCard,
	toggleShowDeleteCard,
	clearCardEditData,
} = accountCardEdit.actions;

export const fetchCardEdit = (data) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/Tarjetas/" + data.cardId;
			dispatch(requestCardEdit());
			//return;
			return axios
				.put(url, JSON.stringify(data), options)
				.then((response) => {
					dispatch(receiveCardEdit());
					dispatch(fetchAccountCards());
					dispatch(toggleShowEditCard({ isOpen: true }));
				})
				.catch((error) => {
					dispatch(receiveCardEdit());
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 404 Not Found
					if (error.response.status === 404) {
						dispatch(fetchAccountCards());
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se pudo modificar la tarjeta porque esta tarjeta ya fue eliminada.",
									details: null,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
					// If 400 BadRequest model state
					else {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									alert: {
										title: "¡Lo sentimos!",
										description:
											"No se ha podido eliminar la tarjeta por el siguiente motivo:",
										details: error.response.data.Message,
									},
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => {
			console.log("Error",err);
		});
};
export const fetchCardDelete = (cardId) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/Tarjetas/" + cardId;
			dispatch(requestCardEdit());
			//return;
			return axios
				.delete(url, options)
				.then((response) => {
					dispatch(receiveCardEdit());
					dispatch(fetchAccountCards());
					dispatch(toggleShowDeleteCard({ isOpenDelete: true }));
					dispatch(
						setAlert({
							alert: {
								title: "Aviso",
								description: "Se eliminó la tarjeta con éxito.",
								details: null,
							},
						})
					);
					dispatch(toggleShowAlert({ isOpen: false }));
				})
				.catch((error) => {
					dispatch(setCardsIsLoading({ isLoading: false }));
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 404 Not Found
					if (error.response.status === 404) {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se ha podido eliminar la tarjeta por el siguiente motivo:",
									details: "No se pudo encontrar la tarjeta.",
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
					// If 400 BadRequest model state
					else {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									alert: {
										title: "¡Lo sentimos!",
										description:
											"No se ha podido eliminar la tarjeta por el siguiente motivo:",
										details: error.response.data.Message,
									},
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => {});
};

export default accountCardEdit.reducer;
